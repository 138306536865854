import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { formatDateText, getMonthlyDueDates, getWeekDaysInDueRange, parseResponseJSON} from "./CommonFunctions";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@mui/material";
import { getPaymentScheduleByIdInDB } from "./PaymentScheduleFunctions";

import { createPaymentDetailsInDB, getPaymentDetailsByIdInDB } from "./PaymentFunctions";
import { createLeaseActivityLogInDB } from "./LeaseActivityLogFunctions";
import { getCollectionPlanByLeaseIdInDB } from "./CollectionPlanFunctions";
import { getCustomerInfoByLeaseIdInDB } from "./CustomerFunctions";
import { getPaymentPlanByLeaseIdInDB } from "./PaymentPlanFunctions";
import { format, parseISO } from "date-fns";

const TakePayment = ({leaseJson,setShowTakePaymentModal,showTakePaymentModal,payment,paymentId,expiredLease}) => {
   
    const [paymentJson,setPaymentJson] = useState(() => {
      if (payment) {
        return payment;
      } else {
        return {
          tenancyId: null,
          receiptNumber: "",
          actualPaymentDate: new Date(),
          cashAmount: 0,
          creditCardAmount: 0,
          otherAmount: 0,
          id: null,
          paidBy: "",
          depositStatus: "undeposited",
        //   paymentScheduleId:null
        collectionPlanId:null,
        expectedRent:0,
        paymentPlanId:null,
        rentalType:"monthly",
        dueDate:null

        };
      }

    });
    const [customersDetails,setCustomersDetails]=useState([]);
    const [unitJson,setUnitJson]=useState({});
    const [propertyJson,setPropertyJson]=useState({})
    const [paymentSchedule,setPaymentSchedule]=useState([]);
    const [collectionPlan,setCollectionPlan]=useState([]);
    const [dueDatesArray, setDueDatesArray]=useState([]);


    const url=process.env.REACT_APP_SERVER_URL;
    
      const handleCloseTakePaymentPopup=()=>{
        setShowTakePaymentModal(false)
      }
      useEffect(() => {
        console.log(leaseJson)

        if(leaseJson && leaseJson.id){
            console.log(leaseJson)
            // getPropertyAndUnitInfoByLeaseId(leaseJson.id);
            getCustomerDetailsByLeaseId(leaseJson.id)
            getDueDatesByLeaseId(leaseJson.id)
            getCollectionPaymentPlanByLeaseId(leaseJson.id)
            // setPaymentJson({...paymentJson,tenancyId:leaseJson.id});
        }
        
        
      }, []);
      useEffect(() => {
        if(paymentJson && paymentJson.leaseId){
          
          getCustomerDetailsByLeaseId(paymentJson.leaseId)
          getDueDatesByLeaseId(paymentJson.leaseId)
        //   getPropertyAndUnitInfoByLeaseId(paymentJson.leaseId)
        //   getPaymentScheduleByLeaseId(paymentJson.leaseId)
        getCollectionPaymentPlanByLeaseId(paymentJson.leaseId)


        }
        
        
        
      }, [paymentJson]);
      useEffect(() => {
        if(paymentId){
          getPaymentDetailsById(paymentId)
        }
        
        
      }, [paymentId]);

    
    const setStateValues=(paymentDetails)=>{
      if(paymentDetails){
        setPaymentJson({...paymentJson,
            creditCardAmount:paymentDetails.creditCardAmount,
            cashAmount:paymentDetails.cashAmount,
            otherAmount:paymentDetails.otherAmount,
            paidBy:paymentDetails.paidBy,
            receiptNumber:paymentDetails.receiptNumber,
            actualPaymentDate:paymentDetails.actualPaymentDate,
            id:paymentDetails.id

        })
      }
    }
    const getPaymentDetailsById = async(paymentId) =>{
      try{
        const response= await getPaymentDetailsByIdInDB(paymentId);
        if(response){
          setPaymentJson(response[0])
          
        }

      }catch(error){
            console.log(error);
      }
    }
    const getPaymentScheduleById = async(paymentScheduleId) =>{
      try{
        const response= await getPaymentScheduleByIdInDB(paymentScheduleId);
        if(response){
          setPaymentSchedule(response)
        }

      }catch(error){
            console.log(error);
      }
    }
    // const getPropertyAndUnitInfoByLeaseId = async(leaseId) =>{
    //   try{
    //     const response1=await getPropertyInfoByLeaseIdInDB(leaseId);
    //     if(response1 && response1.length>0){
          
    //       setPropertyJson(response1[0])
    //     }
    //     const response2=await getUnitInfoByLeaseIdInDB(leaseId);
    //     if(response2 && response2.length>0){
          
    //       setUnitJson(response2[0])
    //     }

    //   }catch(error){
    //         console.log(error);
    //     }
    // }
    const editPaymentDetails=async()=>{
        try{
            await axios.patch(`${url}/payments/${paymentJson.id}`,paymentJson).then((responseForEditingPaymentDetails)=>{
                handleCloseTakePaymentPopup(); 
            })

        }catch(error){
            console.log(error);
        }
    }
    const getCustomerDetailsByLeaseId=async(id)=>{
      try{
        if(id){
        //   await axios.get(`${url}/tenants/${id}/customers`).then((responseForGettingCustomers)=>{
        //     console.log(responseForGettingCustomers.data)
        //     setCustomersDetails(responseForGettingCustomers.data);
        //   return responseForGettingCustomers.data;
        //   });
       
          const response = await getCustomerInfoByLeaseIdInDB(id);
          if(response){
            console.log(response)
            setCustomersDetails(response);
            return response;
          }
        }
          

      }catch(error){
          console.log(error);
      }
  }
    const createPaymentDetails=async()=>{
        try{
            if(paymentJson.paymentPlanId){
              const response= await createPaymentDetailsInDB(paymentJson);
              if(response){
                const tempDbResponse=parseResponseJSON(response);
                if(tempDbResponse){
                    setPaymentJson({...paymentJson,id:tempDbResponse.paymentId}); 
                    const leaseActivityLog={
                      leaseId:paymentJson.leaseId,
                      description: `A payment of ${parseInt(paymentJson.creditCardAmount || 0) + 
                      parseInt(paymentJson.cashAmount||0) + parseInt(paymentJson.otherAmount||0)} 
                      is received with paymentId ${tempDbResponse.paymentId}`
                    }
                    createLeaseActivityLogInDB(leaseActivityLog);
                    handleCloseTakePaymentPopup();
                    window.location.reload();
                }

              }
            
              }
        }
        catch(error){
            console.log(error);
        }
    }

    const getDueDatesByLeaseId = async(leaseId) =>{
      try{
        const response = await getPaymentPlanByLeaseIdInDB(leaseId);
        console.log(response)
        let datesArray=[];
        if(response ){
          for(let itr in response){
            const currentRow = response[itr];
            let startDate = currentRow.startDate;
            let endDate = currentRow.endDate;
            let dates=[]
            if(currentRow.rentalType === "weekly"){
              dates= getWeekDaysInDueRange(startDate, endDate, currentRow.dueOn);
            }
            else{
              dates = getMonthlyDueDates(startDate,endDate, currentRow.dueOn);
            }
            for(let itr in dates){
              // let cr=dates[itr];
              datesArray.push({
                paymentPlanId: currentRow.id,
                dueDate: dates[itr],
                rentalType: currentRow.rentalType
              })
            }
            
          }
        }
        setDueDatesArray(datesArray)

      }catch(error){
        console.log(error);
      }
    }
    
    
    const getCollectionPaymentPlanByLeaseId = async(leaseId)=>{
      try{
        const response = await getCollectionPlanByLeaseIdInDB(leaseId);
        if(response){
          setCollectionPlan(response);
        }

      }catch(error){
        console.log(error);
      }
    }
    return (
        <>
      <Dialog
        open={showTakePaymentModal}
        onClose={handleCloseTakePaymentPopup}
      >
        <DialogTitle>Payment 
            {/* for  Property - {propertyJson.name} <br></br>
        Unit - {unitJson.name} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {/* Payment Plan */}
          
          <FormControl sx={{ m: 1, width: '25ch' }} >
            <InputLabel id="demo-simple-select-autowidth-label">Payment Due Date</InputLabel>
            <Select
            labelId="due-date-select-label"
            value={paymentJson.dueDate || ""}
            onChange={(e) => {
                const selectedPlan = dueDatesArray.find(row => row.dueDate === e.target.value);
                if (selectedPlan) {
                    setPaymentJson(prev => ({
                        ...prev,
                        paymentPlanId: selectedPlan.paymentPlanId,
                        rentalType: selectedPlan.rentalType,
                        dueDate: selectedPlan.dueDate
                    }));
                }
            }}
            label="Payment Due Date"
            variant="outlined"
            >
              {/* {collectionPlan && collectionPlan.map((row, index) => (
                <MenuItem key={index} value={row.id} >{formatDateText(row.dueDate)}</MenuItem>
    
              ))} */}
              {dueDatesArray.map((row, index) => (
                <MenuItem key={index} value={row.dueDate} >{format(parseISO(row.dueDate), "MMM dd, yyyy")}</MenuItem>
    
              ))}
                
            </Select>
        </FormControl>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="receiptNumber"
            label="Receipt Number"
            value={paymentJson.receiptNumber}
            onChange={(e) => setPaymentJson({...paymentJson,receiptNumber:e.target.value})} 
            type="text"
            fullWidth
            variant="standard"
            sx={{m:1,width:'25ch'}}
          />
          <TextField
            required
            type="date"
            margin="dense"
            id="name"
            name="paymentDate"
            value={paymentJson.actualPaymentDate}
            onChange={(e) => setPaymentJson({...paymentJson,actualPaymentDate:e.target.value})} 
            label="Payment Date"
            fullWidth
            variant="standard"
            sx={{m:1,width:'25ch'}}
          />
          <TextField
            required
            type="number"
            margin="dense"
            id="name"
            name="creditCardAmount"
            label="Credit Card Amount"
            fullWidth
            
            value={paymentJson.creditCardAmount}
                    onChange={(e) => setPaymentJson({...paymentJson,creditCardAmount:e.target.value})}
            variant="standard"
            sx={{m:1,width:'20ch'}}
          />
          <TextField
            required
            type="number"
            margin="dense"
            id="name"
            name="cashAmount"
            label="Cash Amount"
            value={paymentJson.cashAmount}
            onChange={(e) => setPaymentJson({...paymentJson,cashAmount:e.target.value})}
            fullWidth
            variant="standard"
            sx={{m:1,width:'15ch'}}
          />
          <TextField
            required
            type="number"
            margin="dense"
            id="name"
            name="otherAmount"
            label="Other Amount"
            fullWidth
            value={paymentJson.otherAmount}
                    onChange={(e) => setPaymentJson({...paymentJson,otherAmount:e.target.value})}
            variant="standard"
            sx={{m:1,width:'15ch'}}
          />
          {!payment && !paymentId ?
          <FormControl sx={{ m: 1, width: '25ch' }} >
            <InputLabel id="demo-simple-select-autowidth-label">Paid By</InputLabel>
            <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            value={paymentJson.paidBy} onChange={(e)=>setPaymentJson({...paymentJson,paidBy:e.target.value})}
            >
                 {customersDetails && Array.isArray(customersDetails) && customersDetails.map((row, index) => (
                    <MenuItem value={row.fname+' '+row.lname}>{row.fname+' '+row.lname}</MenuItem>
                  ))};
            </Select>
        </FormControl>
        : null}
          {/*<TextField
            required
            type="text"
            margin="dense"
            id="name"
            name="paidBy"
            label="Paid By"
            fullWidth
            value={paymentJson.paidBy} onChange={(e)=>setPaymentJson({...paymentJson,paidBy:e.target.value})}
            variant="standard"
            sx={{m:1}}
          />*/}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTakePaymentPopup}>Cancel</Button>
          <Button type="submit" onClick={(e)=>{
            if(paymentJson.id)
            {
                editPaymentDetails();
            }
            else{
                createPaymentDetails();

            }
          }}>Save</Button>
        </DialogActions>
      </Dialog>
                
            
        </>
    );

}
export default TakePayment;