import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useParams 
} from "react-router-dom";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
    FilledInput,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Input,
    TextField,
    InputAdornment,
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import {calculateMonthsAndDays, calculateWeeksAndDays, formatDate} from "../CommonFunctions"
import { getAnyOverLappingLeaseInDB } from '../TenancyFunctions';
import { NumericFormat } from 'react-number-format';
import { createLeaseInfoInDB, getNumberOfPayments } from '../LeaseFunctions';
import { createPaymentPlanInfoInDB, updatePaymentPlanInfoInDB } from '../PaymentPlanFunctions';
import { createCollectionPlanInfoInDB } from '../CollectionPlanFunctions';
const paymentPlan={
    
}
const days= ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const dates= [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];
export default function DummyLeaseInfo({
    showDummyLeaseInfo,setDummyLeaseinfo,leaseJson, setCurrentUnit,currentUnit, setUnits,
    currentPaymentPlan, setCurrentPaymentPlan, setPaymentPlan,paymentPlan,
    setCollectionPlan, editMode="create"}) {
    // console.log(leaseJson)
    const url = process.env.REACT_APP_SERVER_URL;
    // const [currentPaymentPlan,setCurrentPaymentPlan] = useState({
    //     startDate:formatDate(new Date()),
    //     endDate:formatDate(new Date()),
    //     rent:0
    // });
    const tempPaymentPlan = currentPaymentPlan;
    const [dueDatesArray, setDueDaysArray] = useState([]);
    useEffect(() => {
        if(currentPaymentPlan && currentPaymentPlan.hasOwnProperty("rentalType")){
            if(currentPaymentPlan.rentalType==="weekly"){
                setDueDaysArray(days)

            }
            else{
                setDueDaysArray(dates)

            }
        }
        
        
    
      }, [currentPaymentPlan.rentalType]);




      function handleCloseDialog(){
        setDummyLeaseinfo(false);
      }
      const saveLease = async()=>{
        try{
            handleCloseDialog();

            if(editMode === "edit"){
                //updating the paymentPlan
                const response = await updatePaymentPlanInfoInDB(currentPaymentPlan.id, currentPaymentPlan);
                if(response){
                    setPaymentPlan((prevRows) =>
                        prevRows.map((row) => (row.id === currentPaymentPlan.id ? { ...row, ...currentPaymentPlan } : row))
                    );
                  
                    //FIXME: Update the collection plan according in the future
                    // Any change should handle

                }
            }
            else if(editMode === "create"){
                console.log("Creating a lease");
                const response = await createLeaseInfoInDB(leaseJson);
                if(response && response.hasOwnProperty("leaseId")){
                    const leaseId = response.leaseId;
                    let tempcurrentPaymentPlan = currentPaymentPlan;
                    tempcurrentPaymentPlan["leaseId"] = leaseId;
                    
                    const res= await createPaymentPlanInfoInDB(tempcurrentPaymentPlan);
                    if(res){
                        console.log(res.paymentPlanId);
                        let arr=[];
                        tempcurrentPaymentPlan["id"] = res.paymentPlanId
                        arr.push(tempcurrentPaymentPlan);
                        console.log(arr)
                        // FIXMe: NOT UPDATING because its an array.
                        // setPaymentPlan(arr);
                        const resultArray = await getNumberOfPayments({checkin:currentPaymentPlan.startDate,
                            checkout: currentPaymentPlan.endDate,
                            rate: currentPaymentPlan.rent,
                            deposit:0,
                            rentalType:currentPaymentPlan.rentalType
                        })

                        console.log(resultArray);
                        // FIXME: SAVE this collection plan in DB
                        // Fix this due date...this is causing more problems
                        for(let itr in resultArray){
                            let tempJson = {
                                dueDate: resultArray[itr].dueDate,
                                paymentPlanId:res.paymentPlanId
                            }
                            const res1 = await createCollectionPlanInfoInDB(tempJson);
    
                        }
                        setCurrentUnit({...currentUnit, currentLeaseId:leaseId,occupancyStatus:"rented"});
                        // update the units array also
                        setUnits((prevUnitRows) => 
                            prevUnitRows.map((unit) => 
                            unit.id === currentUnit.id
                                ? { ...unit, currentLeaseId:leaseId,occupancyStatus:"rented"} // Update the matching unit
                                : unit // Keep other units unchanged
                            )
                        );
                        // setCollectionPlan(resultArray);
                        
    
                    }
                }
                

            }
            else{
                const leaseId = leaseJson.id;
                if(leaseId){
                    let tempcurrentPaymentPlan = currentPaymentPlan;
                    tempcurrentPaymentPlan["leaseId"] = leaseId;
                    const res= await createPaymentPlanInfoInDB(tempcurrentPaymentPlan);
                    if(res){
                        console.log(res.paymentPlanId);
                        // let arr=[];
                        tempcurrentPaymentPlan["id"] = res.paymentPlanId
                        
                        setPaymentPlan([...paymentPlan, tempcurrentPaymentPlan]); 
                        const resultArray = await getNumberOfPayments({checkin:currentPaymentPlan.startDate,
                            checkout: currentPaymentPlan.endDate,
                            rate: currentPaymentPlan.rent,
                            deposit:0,
                            rentalType:currentPaymentPlan.rentalType
                        })

                        console.log(resultArray);
                        // FIXME: SAVE this collection plan in DB
                        for(let itr in resultArray){
                            let tempJson = {
                                dueDate: resultArray[itr].dueDate,
                                paymentPlanId:res.paymentPlanId
                            }
                            const res1 = await createCollectionPlanInfoInDB(tempJson);
    
                        }
                        // setCollectionPlan(resultArray);
                        
    
                    }

                }

            }
            
            

        }
        catch(error){
            console.log(error);
        }
      }
      const updatePaymentPlan = async()=>{

      }
    
    

  return (
    <>
    <Dialog open={showDummyLeaseInfo} onClose={handleCloseDialog}>
        <DialogContent>
        <Box>
        <div>
    
            <TextField
                sx={{ m: 2, width: '20ch' }}
                label="LEASE START DATE"
                type="date"
                InputLabelProps={{ shrink: true }} // Ensures the label doesn't overlap
                value={currentPaymentPlan.startDate}
                  onChange={(e) => setCurrentPaymentPlan({ ...currentPaymentPlan, startDate: e.target.value })}
            />
            
            <TextField
                sx={{ m: 2, width: '20ch' }}
                label="LEASE END DATE"
                type="date"
                InputLabelProps={{ shrink: true }} // Ensures the label doesn't overlap
                value={currentPaymentPlan.endDate}
                onChange={(e) => setCurrentPaymentPlan({ ...currentPaymentPlan, endDate: e.target.value })}

            />
            {/* <br></br> */}
            <NumericFormat
                sx={{ m: 2, width: '20ch' }}
                label="Rent"
                value={currentPaymentPlan.rent}
                thousandSeparator
                customInput={TextField}
                InputLabelProps={{ shrink: true }}
                  onValueChange={(values) => {
                    setCurrentPaymentPlan({ ...currentPaymentPlan, rent: values.value });
            }}
            />
            <FormControl sx={{ m: 2, width: '20ch' }}>
            <InputLabel id="demo-simple-select-label">Rental Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentPaymentPlan.rentalType}
                label="Rental Type"
                onChange={(e) => setCurrentPaymentPlan({ ...currentPaymentPlan, rentalType: e.target.value })}

            >
                <MenuItem value={"weekly"}>Weekly</MenuItem>
                <MenuItem value={"monthly"}>Monthly</MenuItem>
            </Select>
            </FormControl>

            <FormControl sx={{ m: 2, width: '20ch' }}>
            <InputLabel id="demo-simple-select-label">Due on</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={currentPaymentPlan.dueOn}
                label="Due On"
                onChange={(e) => setCurrentPaymentPlan({ ...currentPaymentPlan, dueOn: e.target.value })}
            >
                {dueDatesArray.map((row, index) => (
                    <MenuItem key={index} value={row}>
                    {row}
                </MenuItem>

                ))}      
            </Select>
            </FormControl>
            
            
            
            
        </div> 
    </Box>

        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button onClick={saveLease}>Save</Button>
        </DialogActions>
    </Dialog>
    
    

    </>
  );
}