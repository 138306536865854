import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { formatDateText, parseResponseJSON} from "./CommonFunctions";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button
} from "@mui/material";
import { getPropertyInfoByTenancyIdInDB, getUnitInfoByTenancyIdInDB } from "./TenancyFunctions";
import { getPaymentScheduleByIdInDB, getPaymentScheduleByTenancyIdInDB } from "./PaymentScheduleFunctions";

import { createPaymentDetailsInDB, getPaymentDetailsByIdInDB } from "./PaymentFunctions";
import { createLeaseActivityLogInDB } from "./LeaseActivityLogFunctions";
import { getCollectionPlanByLeaseIdInDB } from "./CollectionPlanFunctions";

const MakePayment = ({tenancyJson,setShowMakePaymentModal,showMakePaymentModal,payment,paymentId,expiredLease}) => {
   if(expiredLease){
    alert(expiredLease)
   }
    const [paymentJson,setPaymentJson] = useState(() => {
      if (payment) {
        return payment;
      } else {
        return {
          tenancyId: null,
          receiptNumber: "",
          actualPaymentDate: new Date(),
          cashAmount: 0,
          creditCardAmount: 0,
          otherAmount: 0,
          id: null,
          paidBy: "",
          depositStatus: "undeposited",
          paymentScheduleId:null
        };
      }

    });
    const [customersDetails,setCustomersDetails]=useState([]);
    const [unitJson,setUnitJson]=useState({});
    const [propertyJson,setPropertyJson]=useState({})
    const [paymentSchedule,setPaymentSchedule]=useState([]);
    const [collectionPlan,setCollectionPlan]=useState([]);

    const url=process.env.REACT_APP_SERVER_URL;
    
      const handleCloseMakePaymentPopup=()=>{
        setShowMakePaymentModal(false)
      }
      useEffect(() => {
        if(tenancyJson && tenancyJson.id){
            console.log(tenancyJson)
            getPropertyAndUnitInfoByTenancyId(tenancyJson.id)
            setPaymentJson({...paymentJson,tenancyId:tenancyJson.id});
            getCustomerDetailsByTenancyId(tenancyJson.id)
            getPaymentScheduleByTenancyId(tenancyJson.id)
        }
        
        
      }, []);
      useEffect(() => {
        if(paymentJson && paymentJson.tenancyId){
          
          getCustomerDetailsByTenancyId(paymentJson.tenancyId)
          getPropertyAndUnitInfoByTenancyId(paymentJson.tenancyId)
          getPaymentScheduleByTenancyId(paymentJson.tenancyId)
        }
        
        
        
      }, [paymentJson]);
      useEffect(() => {
        if(paymentId){
          getPaymentDetailsById(paymentId)
        }
        
        
      }, [paymentId]);

    
    const setStateValues=(paymentDetails)=>{
      if(paymentDetails){
        setPaymentJson({...paymentJson,
            creditCardAmount:paymentDetails.creditCardAmount,
            cashAmount:paymentDetails.cashAmount,
            otherAmount:paymentDetails.otherAmount,
            paidBy:paymentDetails.paidBy,
            receiptNumber:paymentDetails.receiptNumber,
            actualPaymentDate:paymentDetails.actualPaymentDate,
            id:paymentDetails.id

        })
      }
    }
    const getPaymentDetailsById = async(paymentId) =>{
      try{
        const response= await getPaymentDetailsByIdInDB(paymentId);
        if(response){
          setPaymentJson(response[0])
          
        }

      }catch(error){
            console.log(error);
      }
    }
    const getPaymentScheduleById = async(paymentScheduleId) =>{
      try{
        const response= await getPaymentScheduleByIdInDB(paymentScheduleId);
        if(response){
          setPaymentSchedule(response)
        }

      }catch(error){
            console.log(error);
      }
    }
    const getPropertyAndUnitInfoByTenancyId = async(tenancyId) =>{
      try{
        const response1=await getPropertyInfoByTenancyIdInDB(tenancyId);
        if(response1 && response1.length>0){
          
          setPropertyJson(response1[0])
        }
        const response2=await getUnitInfoByTenancyIdInDB(tenancyId);
        if(response2 && response2.length>0){
          
          setUnitJson(response2[0])
        }

      }catch(error){
            console.log(error);
        }
    }
    const editPaymentDetails=async()=>{
        try{
            await axios.patch(`${url}/payments/${paymentJson.id}`,paymentJson).then((responseForEditingPaymentDetails)=>{
                handleCloseMakePaymentPopup(); 
            })

        }catch(error){
            console.log(error);
        }
    }
    const getCustomerDetailsByTenancyId=async(id)=>{
      try{
        if(id){
          await axios.get(`${url}/tenants/${id}/customers`).then((responseForGettingCustomers)=>{
            console.log(responseForGettingCustomers.data)
            setCustomersDetails(responseForGettingCustomers.data);
          return responseForGettingCustomers.data;
          });
        }
          

      }catch(error){
          console.log(error);
      }
  }
    const createPaymentDetails=async()=>{
        try{
            if(paymentJson.tenancyId){
              const response= await createPaymentDetailsInDB(paymentJson);
              if(response){
                const tempDbResponse=parseResponseJSON(response);
                if(tempDbResponse){
                    setPaymentJson({...paymentJson,id:tempDbResponse.paymentId}); 
                    const leaseActivityLog={
                      tenancyId:paymentJson.tenancyId,
                      description: `A payment of ${parseInt(paymentJson.creditCardAmount || 0) + 
                      parseInt(paymentJson.cashAmount||0) + parseInt(paymentJson.otherAmount||0)} 
                      is received with paymentId ${tempDbResponse.paymentId}`
                    }
                    createLeaseActivityLogInDB(leaseActivityLog);
                    handleCloseMakePaymentPopup();
                }

              }
            
              }
        }
        catch(error){
            console.log(error);
        }
    }
    const getPaymentScheduleByTenancyId = async(tenancyId) =>{
      try{
        const response=await getPaymentScheduleByTenancyIdInDB(tenancyId);
        
        if(response){
          setPaymentSchedule(response);
        }
        // response.map((row, index) => {
        //   console.log(formatDateText(row.dueDate))
        // })
          
          
      }
      catch(error){
        console.log(error);
      }
    }
    
    const getCollectionPaymentPlanByLeaseId = async(leaseId)=>{
      try{
        const response = await getCollectionPlanByLeaseIdInDB(leaseId);
        if(response){
          setCollectionPlan(response);
        }

      }catch(error){
        console.log(error);
      }
    }
    return (
        <>
      <Dialog
        open={showMakePaymentModal}
        onClose={handleCloseMakePaymentPopup}
      >
        <DialogTitle>Payment for  Property - {propertyJson.name} <br></br>
        Unit - {unitJson.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
          {/* Payment Plan */}
          
          <FormControl sx={{ m: 1, width: '25ch' }} >
            <InputLabel id="demo-simple-select-autowidth-label">Payment period</InputLabel>
            <Select
            autoWidth
            value={paymentJson.paymentScheduleId}
            onChange={(e)=>setPaymentJson({...paymentJson,paymentScheduleId:e.target.value})}
            >
              {paymentSchedule && paymentSchedule.map((row, index) => (
                <MenuItem key={index} value={row.id} >{formatDateText(row.dueDate)}</MenuItem>
    
              ))}
                
            </Select>
        </FormControl>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="receiptNumber"
            label="Receipt Number"
            value={paymentJson.receiptNumber}
            onChange={(e) => setPaymentJson({...paymentJson,receiptNumber:e.target.value})} 
            type="text"
            fullWidth
            variant="standard"
            sx={{m:1,width:'25ch'}}
          />
          <TextField
            required
            type="date"
            margin="dense"
            id="name"
            name="paymentDate"
            value={paymentJson.actualPaymentDate}
            onChange={(e) => setPaymentJson({...paymentJson,actualPaymentDate:e.target.value})} 
            label="Payment Date"
            fullWidth
            variant="standard"
            sx={{m:1,width:'25ch'}}
          />
          <TextField
            required
            type="number"
            margin="dense"
            id="name"
            name="creditCardAmount"
            label="Credit Card Amount"
            fullWidth
            
            value={paymentJson.creditCardAmount}
                    onChange={(e) => setPaymentJson({...paymentJson,creditCardAmount:e.target.value})}
            variant="standard"
            sx={{m:1,width:'20ch'}}
          />
          <TextField
            required
            type="number"
            margin="dense"
            id="name"
            name="cashAmount"
            label="Cash Amount"
            value={paymentJson.cashAmount}
            onChange={(e) => setPaymentJson({...paymentJson,cashAmount:e.target.value})}
            fullWidth
            variant="standard"
            sx={{m:1,width:'15ch'}}
          />
          <TextField
            required
            type="number"
            margin="dense"
            id="name"
            name="otherAmount"
            label="Other Amount"
            fullWidth
            value={paymentJson.otherAmount}
                    onChange={(e) => setPaymentJson({...paymentJson,otherAmount:e.target.value})}
            variant="standard"
            sx={{m:1,width:'15ch'}}
          />
          {!payment && !paymentId ?
          <FormControl sx={{ m: 1, width: '25ch' }} >
            <InputLabel id="demo-simple-select-autowidth-label">Paid By</InputLabel>
            <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            autoWidth
            value={paymentJson.paidBy} onChange={(e)=>setPaymentJson({...paymentJson,paidBy:e.target.value})}
            >
                 {customersDetails && Array.isArray(customersDetails) && customersDetails.map((row, index) => (
                    <MenuItem value={row.fname+' '+row.lname}>{row.fname+' '+row.lname}</MenuItem>
                  ))};
            </Select>
        </FormControl>
        : null}
          <TextField
            required
            type="text"
            margin="dense"
            id="name"
            name="paidBy"
            label="Paid By"
            fullWidth
            value={paymentJson.paidBy} onChange={(e)=>setPaymentJson({...paymentJson,paidBy:e.target.value})}
            variant="standard"
            sx={{m:1}}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMakePaymentPopup}>Cancel</Button>
          <Button type="submit" onClick={(e)=>{
            if(paymentJson.id)
            {
                editPaymentDetails();
            }
            else{
                createPaymentDetails();

            }
          }}>Save</Button>
        </DialogActions>
      </Dialog>
                
            
        </>
    );

}
export default MakePayment;