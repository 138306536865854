import { addDays, format, parseISO } from "date-fns";
import { getWeekDaysInDueRange } from "./CommonFunctions";
import { getCustomersByPropertyIdInDB } from "./CustomerFunctions";
import { getAllPaymentsByPropertyIdAndDateRangeInDB, getPaymentsByPropertyIdAndActualPaymentInDB } from "./PaymentFunctions";
import { getActivePaymentPlanByPropertyIdInDB } from "./PaymentPlanFunctions";
import { getAllUnitsByPropertyIdInDB } from "./UnitsFunctions";

export const getMonthlyReport = async(propertyId, searchStartDate, searchEndDate) =>{
    try{
      const unitResponse   = await getAllUnitsByPropertyIdInDB(propertyId);
      const customersResponse = await getCustomersByPropertyIdInDB(propertyId);
      const activeLeaseResponse = await getActivePaymentPlanByPropertyIdInDB(propertyId, searchStartDate, searchEndDate)
      // const taxDetails = await getTaxDetailsByPropertyIdInDB(propertyId);
      const paymentsResponse = await getPaymentsByPropertyIdAndActualPaymentInDB(propertyId, searchStartDate,searchEndDate);
      let totalExpectedRent=0;
        let totalReceivedRent=0;
        let resultArray=[]  ;

      if(unitResponse && activeLeaseResponse && paymentsResponse){
        console.log(paymentsResponse)
        let unitDict={}
        let customerLeaseDict={}
        let unitBoolean={}
        for(let itr in customersResponse){
          let currentRow = customersResponse[itr];
          customerLeaseDict[currentRow.leaseId] = currentRow;
        }
        console.log("consoling out customerLeaseDict");
        console.log(customerLeaseDict)
        for(let itr in unitResponse){
         let currentRow = unitResponse[itr];
         unitDict[currentRow.id] = currentRow;
         unitBoolean[currentRow.id] = false;
         
        }
        console.log("consoling out unitDict");
        console.log(unitDict)
        let leaseToPlan ={};
        let leaseUnitDict={}
        for(let itr in activeLeaseResponse){
          const currentRow = activeLeaseResponse[itr];
          leaseUnitDict[currentRow.unitId] =currentRow;
          let plan= {
            startDate:currentRow.startDate,
            endDate:currentRow.endDate,
            expectedRent:currentRow.rent,
            rentalType:currentRow.rentalType,
            dueOn:currentRow.dueOn
          }
          if(currentRow.leaseId in leaseToPlan){
            
            leaseToPlan[currentRow.leaseId].paymentPlans.push(plan);
          }
          else{
            leaseToPlan[currentRow.leaseId]={
              status:currentRow.status,
              leaseId:currentRow.leaseId,
              unitId:currentRow.unitId,
              leaseDescription:currentRow.leaseDescription,
              paymentPlans:[]
            }
            leaseToPlan[currentRow.leaseId].paymentPlans.push(plan);

          }

        }
        console.log("leaseUnitDict");
        console.log(leaseUnitDict)
        let paymentLeaseDict={};
        for(let itr in paymentsResponse){
          let currentRow = paymentsResponse[itr];
          console.log(currentRow);
          let sum=0;
          if(currentRow.creditCardAmount){
            sum+=currentRow.creditCardAmount
          }
          if(currentRow.cashAmount){
            sum+=currentRow.cashAmount
          }
          if(currentRow.otherAmount){
            sum+=currentRow.otherAmount
          }
          if(currentRow.leaseId in paymentLeaseDict){
            paymentLeaseDict[currentRow.leaseId] +=sum;
          }
          else{
            paymentLeaseDict[currentRow.leaseId] =sum;
          }

        }
        console.log("paymentLeaseDict");
        console.log(paymentLeaseDict)
        
        for(let key in unitDict){
          if(key in leaseUnitDict){
            const leaseId = leaseUnitDict[key].leaseId
            // console.log(leaseId)
            
            totalReceivedRent+=(leaseId in paymentLeaseDict ? paymentLeaseDict[leaseId] : 0)
            let rent = leaseUnitDict[key].rent;
            if(leaseUnitDict[key].rentalType==="weekly"){
                const start = parseISO(searchStartDate);
                const end = parseISO(searchEndDate);
                let current = start;
                let dueCount = 0;

                while (current <= end) {
                    if (format(current, "EEEE").toLowerCase() === leaseUnitDict[key].dueOn.toLowerCase()) {
                        dueCount++;
                    }
                    current = addDays(current, 1);
                }

                rent = dueCount * rent;
            }
            totalExpectedRent+=rent;

            resultArray.push({
              unitName: unitDict[key].name,
              unitId: unitDict[key].id,
              leaseId: leaseId,
              checkin: leaseUnitDict[key].startDate,
              checkout: leaseUnitDict[key].endDate,
              expectedRent: rent,
              rentalType:leaseUnitDict[key].rentalType,
              dueOn:leaseUnitDict[key].dueOn,
              names: leaseId in customerLeaseDict ? customerLeaseDict[leaseId].names : null,
              phoneNumbers: leaseId in customerLeaseDict ? customerLeaseDict[leaseId].phoneNumbers : null,
              receivedRent: leaseId in paymentLeaseDict ? paymentLeaseDict[leaseId] : null
             })
          }
          else{
            resultArray.push({
              unitName: unitDict[key].name,
              unitId: unitDict[key].id,
              leaseId:null
            })
          }
        }


        // setReportSummary(resultArray);
        

      }
      return {
        resultArray: resultArray,
        totalExpectedRent: totalExpectedRent,
        totalReceivedRent: totalReceivedRent
        }

    }
    catch(error){
      console.log(error)
    }
  }