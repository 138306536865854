import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import {
    TextField,
    Paper,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Button,
    Alert,
    Grid,
    Select,
    InputLabel,
    FormControl,
    MenuItem

} from "@mui/material"

import Header from "../Header";
import { NumericFormat,PatternFormat } from "react-number-format";
import CardGroup from "./CardGroup";
import ChartGroup from "./ChartGroup";
import { formatDate, formatDateText } from "../CommonFunctions";
import { parseISO,eachDayOfInterval, format } from "date-fns";
import {
  getAllActiveTenantsInfo,
  getFreeRoomsByDate,
  getCurrentInventory,
  getAllInfoForMonthlyReport,
  getPaymentInfoForGivenMonth
} from "./DataConnections"
import { useParams } from "react-router-dom";
import { getRepairsByPropertyId } from "../RepairFunctions";
import { getMonthlyReport } from "../MonthlyReportData";
let last12Months=[];
const options = { month: 'short', day: 'numeric', year:'numeric' };
let expectedRent = 0;
let receivedRent = 0;
let totalUnits=0;
let occupiedUnits=0;
let repairs=0;

const now=new Date();
now.setDate(1);

const Dashboard=()=>{
  const currentDate=formatDate(new Date());
  const {propertyId}  = useParams();

  const [searchStartDate,setSearchStartDate]=  useState('2025-03-01')
  // useState(formatDate(now));
  const [searchEndDate,setSearchEndDate]=useState('2025-03-31')
  // useState(formatDate(new Date()));
  const [reportSummary,setReportSummary] = useState([])
  const [occupancyReport,setOccupancyReport] = useState([]);
  const [expiredLeasesArray,setExpiredLeases]= useState([]);
  // const [expectedRent,setExpectedRent] = useState(0)
 
    const url=process.env.REACT_APP_SERVER_URL;
    useEffect(() => {
      getLast12Months();
      getAllOtherInfo(propertyId,searchStartDate,searchEndDate);
      getPaymentInfo(propertyId,searchStartDate,searchEndDate);
      // getAllInfo(propertyId,searchStartDate,searchEndDate)
      getRepairs(propertyId)

    }, [searchStartDate,searchEndDate]);


    const getRepairs = async (propertyId) =>{
      try {
        const response= await getRepairsByPropertyId(propertyId);
        if(response){
          repairs=response.length;
        }
        } catch (error) {
          console.log(error)
        }
  
    }
    const getAllOtherInfo = async(propertyId,searchStartDate,searchEndDate) =>{
      try{
        const resultJson = await getMonthlyReport(propertyId, searchStartDate, searchEndDate );
        expectedRent = resultJson.totalExpectedRent;
        totalUnits=resultJson.resultArray.length;
        console.log(resultJson.totalExpectedRent)
        console.log(resultJson.resultArray.length)
        let oc=0;
        for(let itr in resultJson.resultArray){
          const cr = resultJson.resultArray[itr];
          if(cr.leaseId){
            oc+=1;
          }
        }
        occupiedUnits=oc;

        setExpiredLeases(resultJson.resultArray)
        // setExpectedRent(resultJson.totalExpectedRent);
        // setTotalUnits(resultJson.resultArray.length)
        // FIXME: Traverse the array and Fix it

      }catch (error) {
          console.log(error)
        }
    }
    const getPaymentInfo = async(propertyId,searchStartDate,searchEndDate) =>{
      try{
        const response = await getPaymentInfoForGivenMonth(propertyId,searchStartDate,searchEndDate);
        if(response){
          setReportSummary(response)
        }

      }catch (error) {
          console.log(error)
        }
    }
    function getLast12Months() {
      const months = [];
      const currentDate = new Date();
      currentDate.setDate(1); // Set to the first day of the current month
  
      for (let i = 0; i < 12; i++) {
          const startDate = new Date(currentDate);
          let endDate = new Date(currentDate);
          if(i!==0){
          endDate.setMonth(endDate.getMonth() + 1);
          endDate.setDate(0); // Set to the last day of the previous month
          }
          else{
            endDate=new Date();
          }
          
  
          months.push({
              startDate: new Date(startDate),
              endDate: new Date(endDate),
          });
  
          // Move to the previous month
          currentDate.setMonth(currentDate.getMonth() - 1);
      }
  
      last12Months= months;
  }
    const getAllInfo = async (propertyId, searchStartDate, searchEndDate) =>{
      try{
        const response= await getAllInfoForMonthlyReport(propertyId, searchStartDate,searchEndDate);
      // console.log(response)
      if(response){
        // console.log(response)
        expectedRent = response.expectedRent;
        receivedRent = response.receivedRent;
        const returnArray=preprocess(response.reportSummary)
        setReportSummary(returnArray);

      }

      }
      catch(error){
        console.log(error)
      }
    }
  function preprocess(data){
    const dict={};
    let countVacantUnits=0;
    let expiredLeasesArray = [];
    for(let itr in data){
      let unitId=null;
      if(!(data[itr].unitId)){
        countVacantUnits+=1;
        unitId=data[itr].id
        
      }
      else{
        unitId=data[itr].unitId;
        if(data[itr].expiredLease==true){
          expiredLeasesArray.push(data[itr])
        }
        if(data[itr].actualCheckoutDate==null && 
          data[itr].checkout>=searchStartDate && data[itr].checkout<=searchEndDate){
            expiredLeasesArray.push(data[itr])

        }
      }
      if(unitId!=null && !(unitId in dict))
        dict[unitId]=[];
      dict[unitId].push(data[itr]);
    }
    setExpiredLeases(expiredLeasesArray)
    totalUnits = Object.keys(dict).length; //Total Number of units
  occupiedUnits=totalUnits - countVacantUnits;
  return data;
  }
  
    

    return (
      <>
      <Header/>
      <br></br><br></br>
      <Box sx={{  padding:"0% 10%",backgroundColor:"rgb(250, 250, 251)" }}>
      {/* <Grid container alignItems="center" sx={{padding:"0.5% 0%"}}>
          <Grid item>
        <Typography variant="h6" color="text.primary">
          Dashboard
        </Typography>
          </Grid>
          </Grid> */}
      <CardGroup expectedRent={expectedRent} receivedRent={receivedRent} 
      occupiedUnits={occupiedUnits} totalUnits={totalUnits} repairs={repairs}/>
        <br></br>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={8}>
          
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
        <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          onChange={(e)=>{
            setSearchStartDate(formatDate(e.target.value.startDate))
            setSearchEndDate(formatDate(e.target.value.endDate));
          }}
        >
          {last12Months.map((month)=>(
             <MenuItem value={month}>{month.startDate.toLocaleDateString('en-US', options) + " - " + 
              month.endDate.toLocaleDateString('en-US', options)}</MenuItem>
          ))}
          </Select>
          </FormControl>
        </Grid>
      </Grid>
        <ChartGroup 
        paymentReportSummary={reportSummary}
        searchStartDate={searchStartDate}
        searchEndDate={searchEndDate}
        expiredLeases={expiredLeasesArray}
        />
      </Box>
      
      
      </>
    );


}
export default Dashboard;