import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const getCollectionPlanInfoByIdInDB = async(collectionPlanId) =>{
    try{
        const responseForCollectionPlan = await axios.get(`${url}/collectionPlan/${collectionPlanId}`);
        console.log(responseForCollectionPlan)
            if(responseForCollectionPlan && responseForCollectionPlan.data && responseForCollectionPlan.data.length>0){
                return responseForCollectionPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const createCollectionPlanInfoInDB = async(collectionPlanJson) =>{
    try{
        const responseForCollectionPlan = await axios.post(`${url}/collectionPlan`,collectionPlanJson)
            if(responseForCollectionPlan && responseForCollectionPlan.data){
                
                // createCollectionPlanActivityLogInDB(CollectionPlanActivityLog);

                return responseForCollectionPlan.data;
                
              }
              else{
                return "No Data Found"
            }
        
    }
    catch(error)
    {
        console.log(error)
    }
}
export const updateCollectionPlanInfoInDB = async(collectionPlanId,collectionPlanJson) =>{
    try{
        const responseForCollectionPlan = await axios.patch(`${url}/collectionPlan/${collectionPlanId}`,collectionPlanJson);
            if(responseForCollectionPlan && responseForCollectionPlan.data){
                return responseForCollectionPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const deleteCollectionPlanInfoInDB = async(collectionPlanId) =>{
    try{
        const responseForCollectionPlan = await axios.delete(`${url}/collectionPlan/${collectionPlanId}`);
            if(responseForCollectionPlan && responseForCollectionPlan.data){
                return responseForCollectionPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const getCollectionPlanByLeaseIdInDB = async(leaseId) =>{
    try{
        const responseForCollectionPlan = await axios.get(`${url}/collectionPlanByLeaseId/${leaseId}`);
            if(responseForCollectionPlan && responseForCollectionPlan.data){
                return responseForCollectionPlan.data;
              }
              else{
                return []
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getCollectionPlanByCollectionPlanIdInDB = async(collectionPlanId) =>{
    try{
        const responseForCollectionPlan = await axios.get(`${url}/collectionPlanByCollectionPlanId/${collectionPlanId}`);
            if(responseForCollectionPlan && responseForCollectionPlan.data){
                return responseForCollectionPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getActiveCollectionPlanInDB = async()=>{
    try{
        const response  =  await axios.get(`${url}/activeCollectionPlan`);
        if(response && response.data){
            return response.data;
        }
        return [];

    }
    catch(error)
    {
        console.log(error)
    }
}


