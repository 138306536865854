import  React,{
    useState,
    useEffect
} from 'react';
import axios from 'axios';
import { 
    Link, useNavigate 
} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {TablePagination,Box,Button} from '@mui/material'
import Header from '../Header';
import AddIcon from '@mui/icons-material/Add';
import { getAllPropertiesInDB } from '../PropertyFunctions';
import { addDays, endOfMonth, format, parseISO, startOfMonth } from 'date-fns';
import { getActivePaymentPlanForAllPropertiesInDB } from '../PaymentPlanFunctions';
import { getPaymentsForAllPropertiesInDB } from '../PaymentFunctions';
import { NumericFormat } from 'react-number-format';



export default function PropertyList() {
    const [properties,setProperties]= useState([]);
    const url = process.env.REACT_APP_SERVER_URL;
    const navigate=useNavigate();
    const searchStartDate = format(startOfMonth(new Date()), "yyyy-MM-dd");
const searchEndDate = format(endOfMonth(new Date()), "yyyy-MM-dd");
    useEffect(() => {
        getAllProperties();
    
      }, []);

      
    const getAllProperties = async() =>{
        try{
            const propertyResponse = await getAllPropertiesInDB();
            console.log(propertyResponse)
            const paymentsResponse = await getPaymentsForAllPropertiesInDB(searchStartDate,searchEndDate);
            const leaseResponse = await getActivePaymentPlanForAllPropertiesInDB(searchStartDate,searchEndDate);
            console.log(leaseResponse)
            let resultArray = [];
            if(propertyResponse && paymentsResponse && leaseResponse){
              let propertyDict={}
              for(let itr in propertyResponse){
                let cr = propertyResponse[itr];
                cr["expectedRent"]=0;
                propertyDict[cr.id] = cr;
              }
              // let leaseDict={};

              for(let itr in leaseResponse){
                let cr = leaseResponse[itr];
                let rent=cr.rent;
                if(cr.rentalType==="weekly"){
                  const start = parseISO(searchStartDate);
                  const end = parseISO(searchEndDate);
                  let current = start;
                  let dueCount = 0;
                  while (current <= end) {
                    if (format(current, "EEEE").toLowerCase() === cr.dueOn.toLowerCase()) {
                      dueCount++;
                    }
                    current = addDays(current, 1);
                  }
                
                  rent = dueCount * rent;
                }
                
                propertyDict[cr.propertyId].expectedRent +=rent;
                
              }
              for(let itr in paymentsResponse){
                let cr = paymentsResponse[itr];
                let tempJson = propertyDict[cr.propertyId];
                tempJson["receivedAmount"] = cr.receivedAmount
                propertyDict[cr.propertyId] = tempJson;
              }
              for(let key in propertyDict){
                resultArray.push(propertyDict[key])
              }
            }
            setProperties(resultArray)


        }
        catch(error)
        {
            console.log(error)
        }
    }

  return (
    <><Header/>
    <Box sx={{m:1,marginLeft:"60%"}}>
    <Button color="secondary"  variant="outlined" startIcon={<AddIcon />} onClick={(e)=>{
      navigate(`/property`);
    }} >
          Add Property
        </Button>
    </Box>
    <Paper sx={{ width: '80%', overflow: 'hidden',marginLeft:"10%",marginTop:"2%" }}>
      <TableContainer sx={{ maxHeight: window.innerHeight }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
            {/* <TableCell >Property ID</TableCell> */}
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">Units</TableCell>
            <TableCell align="left">Monthly Expected Rent</TableCell>
            <TableCell align="left">Monthly Actual Rent</TableCell>
            <TableCell align="left">Units</TableCell>

            <TableCell align="left">...</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            
                  {properties.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {/* <TableCell component="th" scope="row">
                        <Link to={`/property/${row.id}`} onClick={(e)=>sessionStorage.setItem('propertyName', row.name)}>
                        {row.id}
                        </Link>
                      </TableCell> */}
                      <TableCell align="left" >{row.name}</TableCell>
                      <TableCell align="left">{row.streetAddress}</TableCell>
                      <TableCell align="left"><Link to={`/property/${row.id}/monthlyPaymentReport`}  onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >{row.noOfUnits}</Link></TableCell>
                      <TableCell align="left" ><NumericFormat value={row.expectedRent}
                      thousandSeparator prefix={'$'} displayType="text"/></TableCell>
                      <TableCell align="left" ><NumericFormat value={row.receivedAmount}
                      thousandSeparator prefix={'$'} displayType="text"/></TableCell>

                      <TableCell align="left"><Link to={`/property/${row.id}`} onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >
                        Edit Property Info
                        </Link><br></br>
                        {/* <Link to={`/property/${row.id}/monthlyPaymentReport`} onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >
                        Add Unit
                        </Link><br></br> */}
                        <Link to={`/property/${row.id}/monthlyPaymentReport`} onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >
                        Show Units
                        </Link><br></br>
                        <Link to={`/property/${row.id}/dashboard`} onClick={(e)=>{
                         sessionStorage.setItem('propertyName', row.name);
                      }} >
                        Dashboard
                        </Link>
                        </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
    </>
  );
}