import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { NumericFormat } from 'react-number-format';
import { formatDateText, parseResponseJSON} from "./CommonFunctions";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    
} from "@mui/material";
import {
    GridPagination,
    DataGrid,
    GRID_CHECKBOX_SELECTION_COL_DEF,
  } from '@mui/x-data-grid'
  import CancelIcon from '@mui/icons-material/Close';
import { getPropertyInfoByTenancyIdInDB, getUnitInfoByTenancyIdInDB } from "./TenancyFunctions";
import { getPaymentScheduleByTenancyIdInDB } from "./PaymentScheduleFunctions";
import { columnGroupsStateInitializer } from "@mui/x-data-grid/internals";
import { getCustomerInfoByTenancyIdInDB } from "./CustomerFunctions";
import { getAllPaymentsInfoByLeaseIdAndDateRangeInDB, getAllPaymentsInfoByTenancyIdAndDateRangeInDB, getPaymentHistoryByTenancyIdInDB } from "./PaymentFunctions";
import MakePayment from "./MakePayment";

const PaymentReportIndividualLease = ({leaseJson,unitJson,dueDates,showPaymentReportModal,setShowPaymentReportModal}) => {
 
    const [paymentHistory,setPaymentHistory] = useState([])
    const [showMakePaymentModal,setShowMakePaymentModal]=useState(false);
    const [paymentId,setPaymentId] = useState(null)
    const [totalAmount,setTotalAmount] = useState(0)

    const url=process.env.REACT_APP_SERVER_URL;
    const columns = [
      // { field: 'id', headerName: 'Payment Id', width: 80, align: 'left' },
      { field: 'receiptNumber', headerName: 'Receipt Number', width: 170, align: 'left',
      renderCell:(params)=>{
        if(params.value)
        return <Link onClick={(e)=>{
          setPaymentId(params.row.id)
          setShowMakePaymentModal(true);
        }}>{params.value}</Link>
      }  },
      { field: 'actualPaymentDate', headerName: 'Payment Date', width: 170, align: 'left',valueFormatter: (params) => {
        const formattedDate = params.value ? formatDateText(params.value) :null
        return formattedDate 
      }, },
      { field: 'cashAmount', headerName: 'Cash Amount', width: 130, align: 'left' ,
      renderCell:(params)=>{
        if(params.value)
        return <NumericFormat value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
      } },
      { field: 'creditCardAmount', headerName: 'Credit Card Amount', width: 150, align: 'left' ,
      renderCell:(params)=>{
        if(params.value)
        return <NumericFormat value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
      } },
      { field: 'otherAmount', headerName: 'Other Amount', width: 150, align: 'left' ,
      renderCell:(params)=>{
        if(params.value)
        return <NumericFormat value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
      } },
      { field: 'total', headerName: 'Total', width: 120, align: 'left' ,
      renderCell:(params)=>{
        const total=(params.row.cashAmount || 0) + (params.row.creditCardAmount || 0) + (params.row.otherAmount || 0)
        return <Link onClick={(e)=>{
          setPaymentId(params.row.id)
          setShowMakePaymentModal(true);
        }}><NumericFormat value={total} thousandSeparator prefix={'$'} displayType="text"/></Link>
      } },

        
    ]
    
      const handleClosePaymentReportPopup=()=>{
        setShowPaymentReportModal(false)
      }
     
      
      
      useEffect(() => {
        console.log(leaseJson)
        console.log(dueDates)

        if(leaseJson && leaseJson.id){
          if(dueDates && dueDates.searchStartDate && dueDates.searchEndDate){
            getAllPaymentsInfoByLeaseIdAndDateRange(dueDates.searchStartDate,dueDates.searchEndDate,leaseJson.id);
          }

        }
        
      }, [leaseJson.id,showMakePaymentModal]);

      const getAllPaymentsInfoByLeaseIdAndDateRange= async(searchStartDate,searchEndDate,leaseId) =>{
        try{
          const response=await getAllPaymentsInfoByLeaseIdAndDateRangeInDB(searchStartDate,searchEndDate,leaseId);
          if(response){
            console.log(response)
            setPaymentHistory(response);
            let totalAmount=0;
            for(let i=0;i<response.length;i++){
              totalAmount+=(response[i].cashAmount || 0) + (response[i].creditCardAmount || 0) + (response[i].otherAmount || 0);
            }
            setTotalAmount(totalAmount);
          }

        }catch(error){
          console.log(error)
        }
      }
      function CustomPagination(props) {
        return <>
         <b>Total Amount Received:
            <NumericFormat value={totalAmount} thousandSeparator prefix={'$'} displayType="text"/></b>
            <GridPagination  {...props} /> </>;
      }
    
    return (
        <>
      <Dialog
        open={showPaymentReportModal}
        onClose={handleClosePaymentReportPopup}
        maxWidth="lg"
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>Payment History  
        <IconButton aria-label="close" onClick={handleClosePaymentReportPopup}>
            <CancelIcon />
        </IconButton></DialogTitle>
        <DialogContent>
          <DialogContentText> , 
          </DialogContentText>
          <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={paymentHistory} columns={columns} 
        getRowClassName={(params) =>{
            if(params.row.id%2==0)
            return 'even-row';
            else
            return 'odd-row'
          }}
          getRowId={(row) => row.id}
          slots={{
            pagination: CustomPagination,
          }}
          
         
        />
      </Box>
        </DialogContent>
      </Dialog>
      {showMakePaymentModal && <MakePayment showMakePaymentModal={showMakePaymentModal}
      setShowMakePaymentModal={setShowMakePaymentModal} paymentId={paymentId}
       />}   
            
        </>
    );

}
export default PaymentReportIndividualLease;