import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const getPaymentPlanInfoByIdInDB = async(paymentPlanId) =>{
    try{
        const responseForPaymentPlan = await axios.get(`${url}/paymentPlan/${paymentPlanId}`);
        console.log(responseForPaymentPlan)
            if(responseForPaymentPlan && responseForPaymentPlan.data && responseForPaymentPlan.data.length>0){
                return responseForPaymentPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const createPaymentPlanInfoInDB = async(paymentPlanJson) =>{
    try{
        const responseForPaymentPlan = await axios.post(`${url}/paymentPlan`,paymentPlanJson)
            if(responseForPaymentPlan && responseForPaymentPlan.data){
                
                // createPaymentPlanActivityLogInDB(PaymentPlanActivityLog);

                return responseForPaymentPlan.data;
                
              }
              else{
                return "No Data Found"
            }
        
    }
    catch(error)
    {
        console.log(error)
    }
}
export const updatePaymentPlanInfoInDB = async(paymentPlanId,paymentPlanJson) =>{
    try{
        const responseForPaymentPlan = await axios.patch(`${url}/paymentPlan/${paymentPlanId}`,paymentPlanJson);
            if(responseForPaymentPlan && responseForPaymentPlan.data){
                return responseForPaymentPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const deletePaymentPlanInfoInDB = async(paymentPlanId) =>{
    try{
        const responseForPaymentPlan = await axios.delete(`${url}/paymentPlan/${paymentPlanId}`);
            if(responseForPaymentPlan && responseForPaymentPlan.data){
                return responseForPaymentPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const getPaymentPlanByLeaseIdInDB = async(leaseId) =>{
    try{
        const responseForPaymentPlan = await axios.get(`${url}/paymentPlanByLeaseId/${leaseId}`);
            if(responseForPaymentPlan && responseForPaymentPlan.data){
                return responseForPaymentPlan.data;
              }
              else{
                return []
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getCollectionPlanByPaymentPlanIdInDB = async(paymentPlanId) =>{
    try{
        console.log(paymentPlanId)
        const responseForPaymentPlan = await axios.get(`${url}/collectionPlanByPaymentPlanId/${paymentPlanId}`);
            if(responseForPaymentPlan && responseForPaymentPlan.data){
                return responseForPaymentPlan.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const getActivePaymentPlanByPropertyIdInDB  = async(propertyId,searchStartDate,searchEndDate) =>{
    try{
        const response = await axios.post(`${url}/paymentPlan/byPropertyId/${propertyId}`,{
            searchStartDate,
            searchEndDate
        });
        if(response && response.data){
            return response.data;
        }
        return [];

    }catch(error)
    {
        console.log(error)
    }
}

export const getActivePaymentPlanForAllPropertiesInDB  = async(searchStartDate,searchEndDate) =>{
    try{
        const response = await axios.post(`${url}/paymentPlan/allProperties`,{
            searchStartDate,
            searchEndDate
        });
        if(response && response.data){
            return response.data;
        }
        return [];

    }catch(error)
    {
        console.log(error)
    }
}

