import React, { useState,useEffect, useMemo } from 'react';
import { Grid, Paper, Typography,Box, Card, 
    CardContent, List, ListItem, ListItemButton, 
    ListItemIcon, ListItemText, 
    Divider,Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Icon,
    styled,
    FormControl,
    Select,
    OutlinedInput,
    MenuItem,
    Button,
    TextField,
    Menu,
    IconButton} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllUnitsByPropertyIdInDB } from '../UnitsFunctions';
import { green } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import CardAnalysis from '../AdminDashboard/CardAnalysis';
import { getAllPropertiesInDB } from '../PropertyFunctions';
import { getActiveTenanciesByPropertyIdInDB } from '../TenancyFunctions';
import { getPaymentScheduleByTenancyIdInDB } from '../PaymentScheduleFunctions';
import { getCustomerInfoByLeaseIdInDB, getCustomerInfoByTenancyIdInDB } from '../CustomerFunctions';
import { dateDifference, formatDateText, getDueDate, getMonthlyDueDates, getWeekDaysInDueRange } from '../CommonFunctions';
import Header from '../Header';
import EditIcon from '@mui/icons-material/Edit';
import AddUnit from "../Property/AddUnit";
import AddTenant from './AddTenant';
import AddIcon from '@mui/icons-material/Add';
import { NumericFormat } from 'react-number-format';
import { parseISO,format, setDefaultOptions } from 'date-fns';
import { getRepairByUnitIdInDB } from '../RepairFunctions';
import DummyLeaseInfo from './DummyLeaseInfo';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { getActiveLeasesByPropertyIdInDB, getLeaseInfoByIdInDB } from '../LeaseFunctions';
import { getCollectionPlanByPaymentPlanIdInDB, getPaymentPlanByLeaseIdInDB } from '../PaymentPlanFunctions';
import { getTaxDetailsByPropertyIdInDB } from '../CountyTaxFunctions';
import TakePayment from '../TakePayment';
import { getAllPaymentsInfoByLeaseIdAndDateRangeInDB } from '../PaymentFunctions';

function formatingDate(inputDate){
  if(inputDate){
    const date = parseISO(inputDate); // Parse the ISO string into a Date object

    const formattedDate = format(date, 'MMM dd, yyyy'); // Format the Date object
    
    // console.log(formattedDate); // Output: Jan 25th, 2025
    return formattedDate;
  }
  return null;
  
}


  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
const LeaseGrid = ({properties=[]}) => {
    
    const {propertyId,unitId} = useParams();
    
    const [countyTax,setCountyTax] = useState({})
    const [customers,setCustomers] = useState([]);
    const [paymentSchedule, setPaymentSchedule] = useState([]);
    const [units,setUnits] = useState([])
    const [scheduledPayments,setScheduledPayment] = useState([]);
    const theme = useTheme();
    const [currentProperty,setCurrentProperty] = useState({});
    const [currentUnit,setCurrentUnit] = useState({});
    const [leases,setLeases] = useState([]);
    const [currentTenancy,setCurrentTenancy] = useState({});
    const [showAddUnitModal, setShowAddUnitModal] = useState(false);
    const [repairs,setRepairs] = useState(0);
    const [paymentPlan,setPaymentPlan] = useState([]);
    const [showDummyLeaseInfo,setShowDummyLeaseInfo] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [paymentPlanEditMode,setPaymentPlanEditMode] = useState("create")
    const [leaseJson,setLeaseJson] = useState({
      unitId:null,
      status:"active"
    })
    const [currentUnitId,setCurrentUnitId] = useState(null)
    const [selectedPaymentPlan,setSelectedPaymentPlan] = useState({})
    const [currentPaymentPlan,setCurrentPaymentPlan] = useState({
      rentalType:"monthly"
    })
    const [collectionPlan,setCollectionPlan] = useState([])
    const [currentLease, setCurrentLease] = useState({})
    const [showTakePaymentModal, setShowTakePaymentModal] = useState(false);
    const [dueDatesArray, setDueDatesArray] = useState([]);
    
    const navigate=useNavigate();
   
    // console.log(propertyId);
    const handleChange = (event) => {
        console.log("calling on change");
        setCurrentProperty(
          // On autofill we get a stringified value.
          event.target.value
        );
      };

    useEffect(() => {
        if(currentProperty && currentProperty.id){
            getUnitsAndleasesInfoByPropertyId(currentProperty.id);
            getCountyTaxInfoByPropertyId(currentProperty.id);
           
        }
    }, [currentProperty.id]);
    
    useEffect(() => {
      if(currentLease && currentLease.id){
          getPaymentPlanByLeaseId(currentLease.id)
          getCustomerInfoByLeaseId(currentLease.id)
         
      }
      else{
        setPaymentPlan([]);
        setCurrentPaymentPlan({rentalType:"monthly"});
        setCollectionPlan([]);
        setCustomers([])
      }
  }, [currentLease.id]);

  useEffect(() => {
    if(currentPaymentPlan && currentPaymentPlan.id){
      console.log(currentPaymentPlan.id)
        // getCollectionPlanByPaymentPlanId(currentPaymentPlan.id)
        getDueDatesByLeaseIdAndDateRange(currentPaymentPlan)
        if(currentPaymentPlan.rentalType === "weekly"){
          const dueDates = getWeekDaysInDueRange(currentPaymentPlan.startDate, currentPaymentPlan.endDate, currentPaymentPlan.dueOn);
          setDueDatesArray(dueDates);

        }
        else{
          const temp = parseInt(currentPaymentPlan.dueOn);
          const dueDates = getMonthlyDueDates(currentPaymentPlan.startDate, currentPaymentPlan.endDate,temp);
          setDueDatesArray(dueDates);
        }
       
    }
}, [currentPaymentPlan.id, currentPaymentPlan.startDate, currentPaymentPlan.endDate, currentPaymentPlan.dueOn]);
    useEffect(() => {
        console.log("rendering");
        console.log(properties)
        if(propertyId){
            console.log(typeof propertyId)
            if(properties){
                console.log("rendering 2")
                filterByPropertyId(propertyId)
                
                // 
            }
        }
    }, [properties]);
    
    useEffect(() => {
        setCurrentTenancy({})
        setPaymentSchedule([])
        setCustomers([])
        setRepairs(0)
        if(currentUnit.currentLeaseId!=null){

          getLeaseById(currentUnit.currentLeaseId);
        }
        else{
          setCurrentLease({unitId:currentUnit.id, status:"active"})
        }
        const response=getRepairByUnitIdInDB(currentUnit.id);
        setRepairs(response.length)
        setLeaseJson({...leaseJson,unitId:currentUnit.id})
        
    }, [currentUnit.currentLeaseId]);
   


    useEffect(() => {

        if(currentTenancy && currentTenancy.id){
            // getCustomerInfoByTenancyId(currentTenancy.id);
            getPaymentScheduleByTenancyId(currentTenancy.id);
        }
    
    }, [currentTenancy.id]);

    const totalTax = useMemo(() => 
      countyTax ? countyTax.salesTax + countyTax.tourismTax + countyTax.surfaceTax + countyTax.otherTax : 0
    , [countyTax]);
    
    const getCountyTaxInfoByPropertyId = async(propertyId) =>{
      try{

        const response = await getTaxDetailsByPropertyIdInDB(propertyId);
        console.log(response)
        if(response){
          console.log(response)
          setCountyTax(response);
        }

      }catch(error){
        console.log(error);
      }
    }
    const getLeaseById = async(leaseId) =>{
      try{
        const response  = await getLeaseInfoByIdInDB(leaseId);
        if(response){
          setCurrentLease(response)
        }

      }catch(error){
        console.log(error);
      }
    }


    const getPaymentPlanByLeaseId = async(leaseId) =>{
      try{
        const response = await getPaymentPlanByLeaseIdInDB(leaseId);
        if(response){
          console.log(response);
          setPaymentPlan(response);
        }

      }catch(error){
        console.log(error);
      }

    }
    const getCollectionPlanByPaymentPlanId = async(paymentPlanId) =>{
      try{
        console.log(paymentPlanId)
        const response = await getCollectionPlanByPaymentPlanIdInDB(paymentPlanId);
        if(response){
          console.log(response);
          setCollectionPlan(response)
        }

      }catch(error){
            console.log(error);
      }
    }
    const getCustomerInfoByLeaseId = async(leaseId) =>{
      try{
        const response  = await getCustomerInfoByLeaseIdInDB(leaseId);
        if(response){
          setCustomers(response);
        }

      }catch(error){
            console.log(error);
        }
    }
    const getCustomerInfoByTenancyId= async(tenancyId) =>{
        try{
            const response = await getCustomerInfoByTenancyIdInDB(tenancyId);
            if(response){
                setCustomers(response);
            }

        }catch(error){
            console.log(error);
        }
    }
    const getDueDatesByLeaseIdAndDateRange = async(currentPaymentPlan)=>{
      try{
        const response = await getAllPaymentsInfoByLeaseIdAndDateRangeInDB(
          currentPaymentPlan.startDate, currentPaymentPlan.endDate, currentPaymentPlan.leaseId);
          let dueDateMapping={}
          if(currentPaymentPlan.rentalType === "weekly"){
            const dueDates = getWeekDaysInDueRange(currentPaymentPlan.startDate, currentPaymentPlan.endDate, currentPaymentPlan.dueOn);
            for(let itr in dueDates){
              dueDateMapping[dueDates[itr]]={
                receivedRent:0,
                receivedDate:null
              };
            }
            // setDueDatesArray(dueDates);
  
          }
          else{
            const temp = parseInt(currentPaymentPlan.dueOn);
            const dueDates = getMonthlyDueDates(currentPaymentPlan.startDate, currentPaymentPlan.endDate,temp);
            for(let itr in dueDates){
              dueDateMapping[dueDates[itr]]={
                receivedRent:0,
                receivedDate:null
              };
            }
            // setDueDatesArray(dueDates);
          }
          let resultArray=[];
          if(response){
            for(let itr in response){
              let cr = response[itr];
              let res= getDueDate(currentPaymentPlan.rentalType,currentPaymentPlan.dueOn,
                cr.week_start, cr.week_end, cr.month_start, cr.month_end);
                let total =0;
                if(cr.creditCardAmount){
                  total+=cr.creditCardAmount;
                }
                if(cr.cashAmount){
                  total+=cr.cashAmount;
                }
                if(cr.otherAmount){
                  total+=cr.otherAmount;
                }
              if(res in dueDateMapping){
                dueDateMapping[res].receivedRent = dueDateMapping[res].receivedRent + total
              }
              else{
                dueDateMapping[res].receivedRent = total;
              }
            }
            for(let key in dueDateMapping){
              let tempJson={
                dueDate: key,
                receivedRent: dueDateMapping[key].receivedRent
              }
              resultArray.push(tempJson);
              
            }
          }
          console.log(resultArray)

        setDueDatesArray(resultArray);

      }catch(error){
            console.log(error);
        }
    }
    const getPaymentScheduleByTenancyId= async(tenancyId) =>{
        try{
            const response = await getPaymentScheduleByTenancyIdInDB(tenancyId);
            if(response){
                setPaymentSchedule(response);
                // console.log(response)
            }

        }catch(error){
            console.log(error);
        }
    }
    function filterByPropertyId(propertyId){
        const selectedProperty = properties.find(
            (property) => property.id == propertyId
          );
        if(selectedProperty){
            setCurrentProperty(selectedProperty)
        }
    }
    
    const getUnitsAndleasesInfoByPropertyId = async (propertyId) =>{
        try{
            const unitsInfo= await getAllUnitsByPropertyIdInDB(propertyId);
            if(unitsInfo){
              const leasesInfo= await getActiveLeasesByPropertyIdInDB(propertyId)
              console.log(leasesInfo)
                if(leasesInfo){
                    let unitDict={};
                    for(let itr in unitsInfo){
                        let currentRow=unitsInfo[itr];
                        currentRow["occupancyStatus"] = "vacant";
                        currentRow.currentLeaseId=null;
                        currentRow.rent = 0;
                        unitDict[currentRow.id] = currentRow;
                    }
                    for(let itr in leasesInfo){
                        let currentRow = leasesInfo[itr];
                        if(currentRow.unitId in unitDict)
                        {
                            unitDict[currentRow.unitId].occupancyStatus = "rented";
                            unitDict[currentRow.unitId].currentLeaseId = currentRow.id;
                            unitDict[currentRow.unitId].rent = currentRow.rate;
                        }
                    }
                    let unitArray=[];
                    for(let key in unitDict){
                        unitArray.push(unitDict[key]);
                    }
                    if(unitId){
                        const selectedUnit = unitArray.find((unit)=>
                        unit.id == unitId);
                        if(selectedUnit){
                            setCurrentUnit(selectedUnit)
                        }
                    }
                    setUnits(unitArray)
                    setLeases(leasesInfo);
                    

                }
            }

        }catch(error){
            console.log(error)
        }
    }
    const getAllUnitsByPropertyId = async(propertyId)=>{
        try{

            const response = await getAllUnitsByPropertyIdInDB(propertyId);
            if(response)
            {
                // console.log()
                setUnits(response)
            }

        }
        catch(error){
            console.log(error)
        }
    }
    
    const handleClick = (event,row) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setCurrentPaymentPlan(row)
    };
    console.log(currentPaymentPlan)
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  return (
    <>
    <Header/>
    {currentUnit.currentLeaseId ? 
    <Box sx={{display:"flex", justifyContent:"space-around",mt:2}}>
        <Button></Button>
    <Button variant='outlined' onClick={(e)=>
        navigate(`/property/${propertyId}/units/${currentUnit.id}/lease/${currentUnit.currentTenancyId}`)}>
            Edit</Button></Box> : <Box sx={{display:"flex", justifyContent:"space-around",mt:2}}>
        <Button></Button>
    <Button variant='outlined' onClick={(e)=>
        navigate(`/property/${propertyId}/units/${currentUnit.id}/lease`)}>
            Create Lease</Button></Box>}
    <Grid container spacing={2} style={{ height: '30rem', padding: '16px' }}>
      {/* Left 1/4 */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={3} style={{ height: 'auto', padding: '16px' }}>
            
        <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
        <Select
          
        //   displayEmpty
          value={currentProperty.id || ''}
        //   onChange={handleChange}
          onChange={(e) => {
            filterByPropertyId(e.target.value)
          }}
          input={<OutlinedInput />}
          
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {/* <MenuItem disabled value="">
            <em>Select a Property</em>
          </MenuItem> */}
          {properties.map((property) => (
            <MenuItem
              key={property.id}
              value={property.id}
            >
              {property.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
          {units.map((unitJson) =>{
    
            return <>
            <Box 
            onClick={(e)=>{
                setCurrentUnit(unitJson)
                // console.log(unitJson)
            }}
            sx={{ padding: 1, backgroundColor: currentUnit.id===unitJson.id ? "#cceeff" :"white" }}>
            
            <Grid container spacing={2}>
              {/* First Grid: Left Part */}
              <Grid item xs={10} sm={10} md={10}>
              <Typography sx={{paddingBottom:0.6}}>
              <Typography variant="body2">
                {unitJson.occupancyStatus==="rented" ? <img rel="apple-touch-icon" src="/green-icon.png" width="7%" height="7%" />
                : unitJson.occupancyStatus==="vacant" ? <img rel="apple-touch-icon" src="/red-icon.png" width="7%" height="7%" />
            : <img rel="apple-touch-icon" src="/yellow-icon.png" width="7%" height="7%" />}
               {unitJson.streetAddress}
                </Typography>
                </Typography>
                <Typography variant="body2"></Typography>
              </Grid>
      
              {/* Second Grid: Right Part */}
              <Grid item xs={2} sm={2} md={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                <Typography sx={{paddingBottom:0.6}}>
                 <h5> {unitJson.currentTenancyId ?  
                 <NumericFormat value={unitJson.rent} thousandSeparator prefix={'$'} displayType="text"/>
                 : '-'}</h5>
                </Typography>
                {unitJson.occupancyStatus==="rented" ? 
                <Typography variant="body2" align="right" color="green">
                  Rented
                </Typography> : <Typography variant="body2" align="right" color="red">
                  Vacant
                </Typography>}
              </Grid>
            </Grid>
            
          </Box>
          <Divider/>
          </>
          })}
          
    </Paper>
    
      </Grid>

      {/* Center 2/4 */}
      <Grid item xs={12} sm={12} md={6} container direction="column" spacing={2} width="auto">
        {/* <Grid item>
          <Paper elevation={3} style={{ padding: '16px' }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={12} sm={6} md={3.5}>
                <Item><CardAnalysis title="Lease ends in" isLoss={false} 
                count={currentUnit.currentTenancyId ? dateDifference(currentTenancy.checkout,new Date()) : '-'} 
                
                isMoney={false}  isNumber={false}
                subTitle="days" navigateURL={`/property/${propertyId}/units`} /></Item>
              </Grid>
              <Grid item xs={12} sm={6} md={5}> 
                <Item><CardAnalysis title="Maintenance requests" isLoss={false} count={repairs} isMoney={false} 
                 subTitle="open" navigateURL={`/property/${propertyId}/repairs`}/></Item>
              </Grid>
              <Grid item xs={12} sm={6} md={3.5}>
                <Item><CardAnalysis title="Rent due in" isLoss={true} isMoney={false} 
                count={currentUnit.currentTenancyId ? -3 : '-'}
                subTitle="days" navigateURL={`/property/${propertyId}/monthlyPaymentReport`}/></Item>
              </Grid>
          </Grid>
          </Paper>
        </Grid> */}
        <Grid item>
        <Paper elevation={3} style={{ padding: '10px' }}>
          {/* <Grid container spacing={2}>
                <Grid item xs={10} sm={10} md={10}>
                    <Typography variant="h6">Lease</Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                    <EditIcon/>
                </Grid>
            </Grid> */}
            <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={5}>
            <CustomerCard customers={customers} setCustomers={setCustomers}
            currentLease={currentLease}/> 
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
            <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
              {/* <EditIcon onClick={(e)=>setShowDummyLeaseInfo(true)}/> */}
            {currentUnit.currentLeaseId ? <Table stickyHeader>
                        <TableBody>
                        {paymentPlan.map((row) => (
                            <TableRow 
                            key={row.id}
                            // onClick={(e)=>
                            //   setCurrentPaymentPlan(row)
                            // }
                            sx={{
                              backgroundColor: currentPaymentPlan.id===row.id ? "#cceeff" :"white"}} >
                               <TableCell> {row.startDate + ' - ' + row.endDate}</TableCell>
                               <TableCell> ${row.rent} {row.rentalType}</TableCell>
                               <TableCell> Due on {row.dueOn}</TableCell>
                               <TableCell>  
                               <IconButton
                                aria-label="actions"
                                aria-controls="actions-menu"
                                aria-haspopup="true"
                                onClick={(e)=>{handleClick(e,row)}}
                                // Rotate the icon horizontally
                              >
                                <MoreHorizIcon />
                              </IconButton></TableCell>
                              <Menu
                                id="actions-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                              >
                                <MenuItem onClick={() => { handleClose(); 
                                  setShowDummyLeaseInfo(true);
                                  console.log(currentPaymentPlan.id)
                                  // setCurrentPaymentPlan(row);
                                  setPaymentPlanEditMode("edit")
                                  
                                 }}>
                                  Edit
                                </MenuItem>
                                <MenuItem onClick={() => { handleClose(); 
                                  setShowDummyLeaseInfo(true);
                                 }}>
                                  Delete
                                </MenuItem>
                                <MenuItem onClick={() => { handleClose(); 
                                  setShowDummyLeaseInfo(true);
                                  setPaymentPlanEditMode("extend");
                                  setCurrentPaymentPlan({
                                    startDate:row.endDate,
                                    rent:row.rent,
                                    rentalType:row.rentalType
                                  })

                                 }}>
                                  Extend
                                </MenuItem>
                              </Menu>
                                
                            </TableRow>
                         ))}
                        </TableBody>
                    </Table> : <Button onClick={(e)=>{
                      setShowDummyLeaseInfo(true);
                      setPaymentPlanEditMode("create")
            }}>Create Lease</Button> }
            </Box>
            </Grid>
            </Grid>
            
          </Paper>
        </Grid>
        <Grid item>
          <Paper elevation={3} style={{ height: "320px", padding: '16px' }}>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                    <Typography variant="h6">Payment Schedule</Typography>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                    {currentUnit.currentLeaseId ? <Button onClick={(e)=>{
                      setShowTakePaymentModal(true);
                    }}>Take Payment</Button> : null}
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                    <EditIcon/>
                </Grid>
            </Grid>
            
            
            <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
            <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell> Due Date</TableCell>
                                <TableCell>Rent</TableCell>
                                {/* <TableCell>Amount</TableCell> */}
                                <TableCell>tax</TableCell>
                                <TableCell>Total Rent Due</TableCell>
                                <TableCell>Rent Received</TableCell>
                                <TableCell>Received Date</TableCell>
                                <TableCell>Balance</TableCell>



                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {dueDatesArray.map((row) => (
                            <TableRow>
                               
                                <TableCell>{formatDateText(row.dueDate)}</TableCell>
                                <TableCell>
                                  <NumericFormat value={currentPaymentPlan.rent} thousandSeparator prefix={'$'} displayType="text"/>
                                </TableCell>
                                {/* <TableCell>{row.deposit}</TableCell> */}
                                <TableCell>{totalTax}%</TableCell>
                                <TableCell>
                                <NumericFormat 
                                value={parseInt(currentPaymentPlan.rent) + totalTax*parseInt(currentPaymentPlan.rent)/100} thousandSeparator prefix={'$'} displayType="text"/>
                                  </TableCell>
                                <TableCell>
                                  <NumericFormat value={row.receivedRent} thousandSeparator prefix={'$'} displayType="text"/>
                                </TableCell>
                                <TableCell>{row.actualPaymentDate ? 
                                formatDateText(row.actualPaymentDate) : null}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table> 
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Right 1/4 */}
      <Grid item xs={12} sm={6} md={3} container direction="column" spacing={2}>
        <Grid item>
            {/* <Typography variant="h6">Right Upper Section</Typography> */}
            <Card sx={{ maxWidth: 275 }}>
            <CardContent>
                <Box sx={{display:"flex",justifyContent:"space-around",padding:1.5}}>
                    <h6>{currentUnit.name}<br></br> {currentUnit.streetAddress} </h6>
                    <EditIcon onClick={(e)=>{
                        setShowAddUnitModal(true);
                    }}/>
                </Box>

            </CardContent>
        </Card>
        </Grid>
        <Grid item>
            
            {/* <CustomerCard customers={customers} setCustomers={setCustomers}
            currentTenancy={currentTenancy}/>  */}
          {/* <Paper elevation={3} style={{ height: '50%', padding: '16px' }}> */}
            {/* <Typography variant="h6">Right Lower Section</Typography> */}
          {/* </Paper> */}
          
        </Grid>
      </Grid>
    </Grid>
    {showAddUnitModal && <AddUnit showAddUnitModal={showAddUnitModal} 
    setShowAddUnitModal={setShowAddUnitModal}
    unitRows={units} setUnitRows={setUnits} unitJson={currentUnit}   setUnitJson={setCurrentUnit}   />}
    {showDummyLeaseInfo && <DummyLeaseInfo setDummyLeaseinfo={setShowDummyLeaseInfo}
    showDummyLeaseInfo={showDummyLeaseInfo} leaseJson={currentLease} paymentPlan={paymentPlan}
    currentPaymentPlan={currentPaymentPlan} editMode={paymentPlanEditMode} currentUnit={currentUnit}
    setCurrentPaymentPlan = {setCurrentPaymentPlan} setPaymentPlan={setPaymentPlan}
    setCollectionPlan={setCollectionPlan} setCurrentUnit={setCurrentUnit} setUnits={setUnits}/>}

    {showTakePaymentModal && <TakePayment leaseJson={currentLease} 
    showTakePaymentModal={showTakePaymentModal}
    setShowTakePaymentModal={setShowTakePaymentModal}
    />}
    </>
  );
};

const CustomerCard =({customers,setCustomers,currentLease})=>{
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showAddTenantModal, setShowAddTenantModal] =useState(false);
    const [editMode,setEditMode] = useState("edit");
    

    useEffect(() => {
      setCurrentIndex(0);
      
    }, [currentLease.id]);

    const handleNext = () => {
        if (currentIndex < customers.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      };
      // Handler for navigating to the previous tenant
      const handleBack = () => {
        if (currentIndex > 0) {
          setCurrentIndex((prevIndex) => prevIndex - 1);

        }
      };
  const currentTenant = customers[currentIndex];
  // console.log(currentTenancy.hasOwnProperty("id"))
    return (
      <>
      {currentLease.hasOwnProperty("id") && customers.length===0 ? 
        <Button onClick={(e)=>{
          setEditMode("create")
          setShowAddTenantModal(true)}
        }>Add Tenant</Button> : null}
      {customers.length>0 ? 
        <Card sx={{ maxWidth: 275 }}>
            <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={8}><h6>Tenant</h6>
              </Grid>
              <Grid item xs={3} sm={3} md={2}>
                <AddIcon onClick={(e)=>{
                  setEditMode("create")
                  setShowAddTenantModal(true)
                }}/>
              </Grid>
              <Grid item xs={3} sm={3} md={2}>
                <EditIcon onClick={(e)=>{
                  setEditMode("edit")
                  setShowAddTenantModal(true)}
                }/>
              </Grid>
            </Grid>
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountCircleOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentTenant.fname + ' ' 
                                  +currentTenant.lname} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <EmailOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentTenant.email} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <PhoneAndroidOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary={currentTenant.phone} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </nav>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <Button variant="outlined" disabled={currentIndex === 0} onClick={handleBack}>
                        Back
                      </Button>
                      <Button variant="outlined" disabled={currentIndex === customers.length - 1} onClick={handleNext}>
                        Next
                      </Button>
                    </Box>
                </Box>

            </CardContent>
        
        </Card> : null}
        {showAddTenantModal && <AddTenant showAddTenantModal={showAddTenantModal}
        setShowAddTenantModal={setShowAddTenantModal} rows={customers} setRows={setCustomers}
        customer={editMode === "edit" ? currentTenant : null} 
        leaseId={currentLease.id} saveInDB={true}
        />}

        
        </>
    )
}
export default LeaseGrid;
