import axios from "axios";
import { calculateMonthsAndDays, calculateWeeksAndDays } from "./CommonFunctions";
const url = process.env.REACT_APP_SERVER_URL;
export const getLeaseInfoByIdInDB = async(leaseId) =>{
    try{
        const responseForLease = await axios.get(`${url}/lease/${leaseId}`);
        console.log(responseForLease)
            if(responseForLease && responseForLease.data && responseForLease.data.length>0){
                return responseForLease.data[0];
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const createLeaseInfoInDB = async(leaseJson) =>{
    try{
        const responseForLease = await axios.post(`${url}/lease`,leaseJson)
            if(responseForLease && responseForLease.data){
                
                // createLeaseActivityLogInDB(leaseActivityLog);

                return responseForLease.data;
                
              }
              else{
                return "No Data Found"
            }
        
    }
    catch(error)
    {
        console.log(error)
    }
}
export const updateLeaseInfoInDB = async(leaseId,leaseJson) =>{
    try{
        const responseForLease = await axios.patch(`${url}/lease/${leaseId}`,leaseJson);
            if(responseForLease && responseForLease.data){
                return responseForLease.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const deleteLeaseInfoInDB = async(leaseId) =>{
    try{
        const responseForLease = await axios.delete(`${url}/lease/${leaseId}`);
            if(responseForLease && responseForLease.data){
                return responseForLease.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}
export const getActiveLeasesByPropertyIdInDB = async(propertyId) =>{
    try{
        const responseForLease = await axios.get(`${url}/leaseByProperty/${propertyId}`);
            if(responseForLease && responseForLease.data){
                return responseForLease.data;
              }
              else{
                return "No Data Found"
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getAllDetailsOfActiveLeasesByPropertyIdInDB = async(propertyId) =>{
    try{
        const responseForLease = await axios.get(`${url}/leases/expired/${propertyId}`);
            if(responseForLease && responseForLease.data){
                return responseForLease.data;
              }
              else{
                return [];
            }

    }
    catch(error)
    {
        console.log(error)
    }
}
export const getMonthlyDueDatesInDB = async(propertyId, searchStartDate,searchEndDate) =>{
    try{
        const response = await axios.post(`${url}/lease/monthlyDueDateReport`,{
            propertyId,
            searchStartDate,
            searchEndDate
        })
        if(response && response.data){
            return response.data;
        }
        return [];

    }catch(error){
        console.log(error)
    }
}

export const getNumberOfPayments = async(tenancyJson,proRate = false, ) =>{
    try{
        
        let index=1;
        const dueDates=[];
        const tempArrayCheckin=tenancyJson.checkin.split('-');
        if(tempArrayCheckin.length==3){
            let yearCheckin=parseInt(tempArrayCheckin[0]);
            let monthCheckin=parseInt(tempArrayCheckin[1]);
            let dateCheckin=parseInt(tempArrayCheckin[2]);
            const tempDateCheckin=proRate==false ? dateCheckin : 1;
            // const totalTaxPer= calculateTotalTaxPer(taxJson)
            const totalTaxPer = 7;
          if(tenancyJson.rentalType=="monthly"){

            let diffJson;
            if(proRate){
                diffJson=calculateMonthsAndDays(""+yearCheckin+"-"+monthCheckin+"-1",tenancyJson.checkout);
            }
             
            else{
                diffJson=calculateMonthsAndDays(tenancyJson.checkin,tenancyJson.checkout)
            }
            
            for(let i=1;i<=diffJson.months;i++)
            {
                if(i==1){
                    dueDates.push({id:index,
                        rent:tenancyJson.rate,
                        deposit:tenancyJson.deposit,
                        tax:(totalTaxPer * tenancyJson.rate)/100,
                        dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin),
                        description:"Pre-payment including deposit"
                    })
                }
                else{
                    let rent=tenancyJson.rate;
                    if(i==2 && proRate){
                        
                        const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                        
                        rent=Math.round(((daysInMonth-dateCheckin+1) * rent)/daysInMonth,0);
                        
                    }
                    dueDates.push({id:index,
                        rent:rent,
                        tax:(totalTaxPer * rent)/100,
                        dueDate:new Date(yearCheckin,monthCheckin-1,tempDateCheckin)
                    })
                }
                
                // console.log(new Date(yearCheckin,monthCheckin-1,dateCheckin))
                    if(monthCheckin==12){
                        monthCheckin=1;
                        yearCheckin+=1;
                    }
                    else{
                        monthCheckin +=1;
                    }
                    index++;
            }
            if(diffJson.days>0){
                const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                let rent=Math.round(((diffJson.days) * tenancyJson.rate)/daysInMonth,0);
                dueDates.push({id:index,
                    rent:rent,
                    tax:(totalTaxPer * rent)/100,
                    dueDate:new Date(yearCheckin,monthCheckin-1,tempDateCheckin),
                    description:"For Remaining " + diffJson.days + " days"
                })
            }
            return dueDates;
           
            }
            else{
                const diffJson=calculateWeeksAndDays(tenancyJson.checkin,tenancyJson.checkout)
                for(let i=1;i<=diffJson.weeks;i++)
                {
                    const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                    // console.log(new Date(yearCheckin,monthCheckin-1,dateCheckin))
                    if(i==1){
                        dueDates.push({id:index,
                            rent:tenancyJson.rate,
                            deposit:tenancyJson.deposit,
                            tax:(totalTaxPer * tenancyJson.rate)/100,
                            dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin),
                            description: "Pre-payment including deposit"
                        });

                    }
                    else{
                        dueDates.push({id:index,
                            rent:tenancyJson.rate,
                            tax:(totalTaxPer * tenancyJson.rate)/100,
                            dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin)});

                    }
                    if(dateCheckin + 7>daysInMonth ){
                        const diff=daysInMonth-dateCheckin;
                        if(monthCheckin==12){
                            monthCheckin=1;
                            yearCheckin+=1;
                        }
                        else{
                            monthCheckin +=1;
                        }
                        dateCheckin=diff;
            
                    }
                    else{
                        dateCheckin =dateCheckin + 7;
                    }
                    index++;
                }
                if(diffJson.days>0){
                    dueDates.push({id:index,
                        rent:tenancyJson.rate,
                        tax:(totalTaxPer * tenancyJson.rate)/100,
                        dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin),
                        description:"For Remaining " + diffJson.days + " days"
                    })
                }



                // let dayInWeek=new Date(yearCheckin, monthCheckin-1, dateCheckin).getDay();
                // while(dayInWeek!=tenancyJson.paymentDueDate){
                //     dateCheckin =(dateCheckin+1)%7;
                //     dayInWeek=new Date(yearCheckin, monthCheckin-1, dateCheckin).getDay();
                // }
                // while(yearCheckin<=yearCheckout && monthCheckin<=monthCheckout && dateCheckin<dateCheckout){
                //     const  daysInMonth=new Date(yearCheckin, monthCheckin, 0).getDate();
                //     console.log(new Date(yearCheckin,monthCheckin-1,dateCheckin))
                //     dueDates.push({id:index,rent:tenancyJson.rate,dueDate:new Date(yearCheckin,monthCheckin-1,dateCheckin)});
                //     index++;
                //     if(dateCheckin +7 <=dateCheckout){
                //         if(dateCheckin + 7>daysInMonth ){
                //             const diff=daysInMonth-dateCheckin;
                //             if(monthCheckin==12){
                //                 monthCheckin=1;
                //                 yearCheckin+=1;
                //             }
                //             else{
                //                 monthCheckin +=1;
                //             }
                //             dateCheckin=diff;
    
                //         }
                //         else{
                //             dateCheckin =dateCheckin + 7;
                //         }
                //     }
                //     else{
                //         break;
                //     }
                    //dueDates.push({id:index,rent:tenancyJson.rate,dueDate:
                    // new Date(yearCheckin,monthCheckin-1,dateCheckin)})
                        
                // }
                return dueDates;

            }
        }

                
            
       
        return [];


    }
    catch(error){
        console.log(error)
    }
  }

