import React, {
    useState,
    useEffect,
    useContext,
    forwardRef,
    useImperativeHandle,
    useRef,
  } from 'react'
  import axios from 'axios'
  import { useNavigate, useLocation, useParams } from 'react-router-dom'
  import { Link, redirect } from 'react-router-dom'
 
  import {
    dateDifference,
    getDataSorted,
    formatDateText,
    formatDate,
  } from './CommonFunctions'

  import Header from './Header'
  import MakePayment from './MakePayment'
  import { Table, Rate, RadioTile, IconButton, Divider } from 'rsuite'
  import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline'
  import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline'
import { getAllUnitsByPropertyIdInDB } from './UnitsFunctions'
import { getCustomersByPropertyIdInDB } from './CustomerFunctions'
import { getActivePaymentPlanByPropertyIdInDB } from './PaymentPlanFunctions'
  
  
  const { Column, HeaderCell, Cell } = Table
  const rowKey = 'longTermTenancyId'
  
  const AllTenants = () => {
    let [activeTenancyDetails, setActiveTenancyDetails] = useState([])
    const [sortColumn, setSortColumn] = useState('stayDuration')
    const [sortType, setSortType] = useState('asc')
    const [loading, setLoading] = useState(false)
    const [showMakePaymentModal, setShowMakePaymentModal] = useState(false)
    const [showSendMessageModal, setShowSendMessageModal] = useState(false)
    const [showFollowUpModal, setShowFollowUpModal] = useState(false)
    const [rowDataProp, setRowDataProp] = useState({})
    const [tenancyId, setTenancyId] = useState('')
    const [showReview, setShowReview] = useState(false)
    const [tenantId, setTenantId] = useState('')
    const [expandedRowKeys, setExpandedRowKeys] = useState([])
    const [paymentInformation, setPaymentInformation] = useState([])
    const [reportSummary, setReportSummary] = useState([])
    const [tenancyJson,setTenancyJson]=useState({})
    const url = process.env.REACT_APP_SERVER_URL;
    const {propertyId} =useParams();
    let totalRent = 0
    const navigate = useNavigate()
    useEffect(() => {
      if(propertyId){
        getActiveTenants(propertyId)

      }
      else{
        getActiveTenants(null)
      }
      
    }, [propertyId])
  
    const getActiveTenants = async (propertyId) => {
    
      try {
        const selectedDate=new Date();
                  const year = selectedDate.getFullYear();
                  const month = selectedDate.getMonth();
        
                  // Calculate the start date of the month
                  const startDate = formatDate(new Date(year, month, 1)); // Set day to 1st of the month
        
                  // Calculate the end date of the month
                  const endDate = formatDate(new Date(year, month + 1, 0));
        const unitResponse   = await getAllUnitsByPropertyIdInDB(propertyId);
              const customersResponse = await getCustomersByPropertyIdInDB(propertyId);
              const activeLeaseResponse = await getActivePaymentPlanByPropertyIdInDB(propertyId, startDate, endDate)
              
              if(unitResponse && activeLeaseResponse){
                let unitDict={}
                let customerLeaseDict={}
                let unitBoolean={}
                let resultArray=[]  ;
                for(let itr in customersResponse){
                  let currentRow = customersResponse[itr];
                  customerLeaseDict[currentRow.leaseId] = currentRow;
                }
                console.log("consoling out customerLeaseDict");
                console.log(customerLeaseDict)
                for(let itr in unitResponse){
                 let currentRow = unitResponse[itr];
                 unitDict[currentRow.id] = currentRow;
                 unitBoolean[currentRow.id] = false;
                 
                }
                console.log("consoling out unitDict");
                console.log(unitDict)
                let leaseUnitDict={}
                for(let itr in activeLeaseResponse){
                  const currentRow = activeLeaseResponse[itr];
                  leaseUnitDict[currentRow.unitId] =currentRow;
                }
                console.log("consoling out leaseUnitDict");
                console.log(leaseUnitDict)
                for(let key in unitDict){
                  if(key in leaseUnitDict){
                    const leaseId = leaseUnitDict[key].leaseId
                    // console.log(leaseId)
                    resultArray.push({
                      unitName: unitDict[key].name,
                      unitId: unitDict[key].id,
                      checkin: leaseUnitDict[key].startDate,
                      checkout: leaseUnitDict[key].endDate,
                      expectedRent: leaseUnitDict[key].rent,
                      rentalType:leaseUnitDict[key].rentalType,
                      dueOn:leaseUnitDict[key].dueOn,
                      names: leaseId in customerLeaseDict ? customerLeaseDict[leaseId].names : null,
                      phoneNumbers: leaseId in customerLeaseDict ? customerLeaseDict[leaseId].phoneNumbers : null,
        
                     })
                  }
                  else{
                    resultArray.push({
                      unitName: unitDict[key].name,
                      unitId: unitDict[key].id,
                    })
                  }
                }
        
        
                setReportSummary(resultArray);
              }
      } catch (error) {
        console.log(error)
      }
    }
  
    const handleSortColumn = (sortColumn, sortType) => {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        setSortColumn(sortColumn)
        setSortType(sortType)
      }, 500)
    }
    function countOverstayedGuests(activeTenancyDetails) {
      var count = 0
      for (var i = 0; i < activeTenancyDetails.length; i++) {
        if (activeTenancyDetails[i].stayDuration < 0) {
          count = count + 1
        }
      }
      return count
    }
  
    function countTodayCheckoutGuests(activeTenancyDetails) {
      var count = 0
      for (var i = 0; i < activeTenancyDetails.length; i++) {
        if (activeTenancyDetails[i].stayDuration === 0) {
          count = count + 1
        }
      }
      return count
    }
    function countTmroCheckoutGuests(activeTenancyDetails) {
      var count = 0
      for (var i = 0; i < activeTenancyDetails.length; i++) {
        if (activeTenancyDetails[i].stayDuration === 1) {
          count = count + 1
        }
      }
      return count
    }
    function countTotalRent(activeTenancyDetails) {
      var count = 0
      for (var i = 0; i < activeTenancyDetails.length; i++) {
        count += activeTenancyDetails[i].rate
      }
      return count
    }
  
    // FIXME: export the table to excel
    return (
      <>
      <Header/>
        <h4 className="mt-3 ms-5"> Today's Guests</h4>
        <h5 className="mt-0 me-5 mb-0" style={{ marginLeft: '50%' }}>
          Active Guests: {activeTenancyDetails.length}
          &nbsp; &nbsp; Overstayed Guests :{' '}
          {countOverstayedGuests(activeTenancyDetails)}
          &nbsp; &nbsp; Today Checkouts :{' '}
          {countTodayCheckoutGuests(activeTenancyDetails)}
          &nbsp; &nbsp; Tomorrow Checkouts :{' '}
          {countTmroCheckoutGuests(activeTenancyDetails)}{' '}
        </h5>
        <h5 style={{ marginLeft: '50%', color: 'white' }}>
          &nbsp; &nbsp; Total Rent : {'$' + countTotalRent(activeTenancyDetails)}{' '}
        </h5>
        <br></br>
        <Table
          className="ms-5"
          autoHeight
          // height={2000}
          wordWrap="break-word"
          //   data={activeTenancyDetails}
          data={getDataSorted(sortColumn, sortType, reportSummary)}
          size="lg"
          sortColumn={sortColumn}
          sortType={sortType}
          onSortColumn={handleSortColumn}
          loading={loading}
          headerHeight={40}
          shouldUpdateScroll={false}
        >
          <Column width={140} fixed sortable>
            <HeaderCell>
              <b>Unit Name</b>
            </HeaderCell>
            <Cell dataKey="unitName">
              {(rowData) => (
                <a
                  onClick={(e) => {
                    // setValueTenancyId(row.id);
                    navigate(`/property/${propertyId}/units/${rowData.unitId}/overall`);
                  }}
                >
                  <Link>{rowData.unitName}</Link>
                </a>
              )}
            </Cell>
          </Column>
          <Column width={100} sortable>
            <HeaderCell>Days </HeaderCell>
            <Cell style={{ padding: '6px' }} dataKey="stayDuration">
              {(rowData) => (
                <>
                  {rowData.stayDuration < 0 ? (
                    <label style={{ color: 'red' }}>
                      Overstay {rowData.stayDuration}
                    </label>
                  ) : rowData.stayDuration === 0 ? (
                    <label>Checkout Today</label>
                  ) : (
                    <label>{rowData.stayDuration}</label>
                  )}
                </>
              )}
            </Cell>
          </Column>
  
          
          {/* <Column width={100} sortable>
          <HeaderCell>Vacancy </HeaderCell>
          <Cell dataKey="Vacancy" />
        </Column> */}
          <Column width={160} sortable>
            <HeaderCell>Names</HeaderCell>
            <Cell dataKey="names" />
          </Column>
  
          <Column width={160} sortable>
            <HeaderCell>phoneNumbers</HeaderCell>
            <Cell dataKey="phoneNumbers" />
          </Column>
  
          <Column width={140} sortable>
            <HeaderCell>Checkin</HeaderCell>
            <Cell dataKey="checkin">
              {(rowData) => <>{rowData.checkin ? formatDateText(rowData.checkin) : null}</>}
            </Cell>
          </Column>
  
          <Column width={150} sortable>
            <HeaderCell>Expected Checkout</HeaderCell>
            <Cell dataKey="checkout">
              {(rowData) => <>{rowData.checkout ? formatDateText(rowData.checkout) : null}</>}
            </Cell>
          </Column>
  
          <Column width={100} sortable>
            <HeaderCell>Rent </HeaderCell>
            <Cell dataKey="expectedRent">
              {(rowData) => (
                <>
                  <b> $</b>
                  {rowData.expectedRent}
                </>
              )}
            </Cell>
          </Column>

          <Column width={120} sortable>
            <HeaderCell>Rental Type </HeaderCell>
            <Cell dataKey="rentalType">
              
            </Cell>
          </Column>
          <Column width={100} sortable>
            <HeaderCell>Due On</HeaderCell>
            <Cell dataKey="dueOn">
              
            </Cell>
          </Column>
          {/* <Column width={100} sortable>
          <HeaderCell>Balance </HeaderCell>
          <Cell dataKey="Balance">
          {rowData => (
              <>
                {rowData.rate - rowData.paidAmount}
              </>
            )}
          </Cell>
        </Column> */}
  
          {/* <Column  width={70}  sortable>
          <HeaderCell>Id</HeaderCell>
          <Cell style={{ padding: '6px' }}>
            {rowData => (
              <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
                Edit
              </Button>
            )}
          </Cell>
        </Column> */}
          {/* <Column width={120} sortable>
            <HeaderCell>Tenant Review </HeaderCell>
            <Cell dataKey="guestQuality">
              {(rowData) =>
                rowData.reviewCount == '0' ? (
                  <span>No Reviews</span>
                ) : (
                  <span>
                    {rowData.averageOverallRating + ' '}
                    <a
                      style={{
                        cursor: 'pointer',
                        background: 'none',
                        color: 'blue',
                      }}
                      onClick={(e) => {
                        setTenantId(rowData.id)
                        setShowReview(true)
                      }}
                    >
                      {'[' + rowData.reviewCount + '] '} Reviews
                    </a>
                  </span>
                )
              }
            </Cell>
          </Column> */}
  
          <Column width={120}>
            <HeaderCell>...</HeaderCell>
            <Cell>
              {(rowData) => (
                <>
                  <Link
                    onClick={(e) => {
                      setTenancyJson(rowData)
                      setShowMakePaymentModal(true)
                    }}
                  >
                    Payment
                  </Link>{' '}
                  {/* <Divider vertical></Divider> */}
                  <br></br>
                  <Link to={`/property/${propertyId}/units/${rowData.unitId}/overall`}
                    onClick={(e) => {
                      
                    }}
                  >
                    Edit Lease
                  </Link>
                  <br></br>
                  
                  {/* 2 Days  */}
                  {rowData.stayDuration <= 1 ? (
                    rowData.renewing == 1 ? (
                      <b style={{ color: 'green' }}>Renewing</b>
                    ) : rowData.renewing == 0 ? (
                      <b style={{ color: 'red' }}>Not Renewing</b>
                    ) : (
                      <>
                        <Link
                          onMouseMove={(e) => setShowFollowUpModal(false)}
                          onTouchMove={(e) => setShowFollowUpModal(false)}
                          onClick={(e) => {
                            setTenancyId(rowData.id)
                            setShowFollowUpModal(true)
                            setRowDataProp(rowData)
                          }}
                        >
                          <b style={{ color: 'red' }}>Follow up</b>
                        </Link>
                      </>
                    )
                  ) : null}
                </>
              )}
            </Cell>
          </Column>
          {/* <Column width={80} fixed="right">
          <HeaderCell>...</HeaderCell>
          <Cell><Link onMouseMove={(e)=>setShowMakePaymentModal(false)} onClick={(e)=>setShowMakePaymentModal(true)}>Message Guest</Link></Cell>
        </Column> */}
        </Table>
        {showMakePaymentModal && (
          <MakePayment
            tenancyJson={tenancyJson}
            showMakePaymentModal={showMakePaymentModal}
            setShowMakePaymentModal={setShowMakePaymentModal}
          />
        )}
        {/* {showReview && (
          <CustomerReviewModal
            data={{ showReview, setShowReview, tenantId }}
          ></CustomerReviewModal>
        )}
        
        {showSendMessageModal && <SendSMS />}
        {showFollowUpModal && (
          <AddFollowUp
            showFollowUpModal={showFollowUpModal}
            data={rowDataProp}
            activeTenancyDetails={activeTenancyDetails}
            setShowFollowUpModal={setShowFollowUpModal}
          />
        )} */}
      </>
    )
  }
  const ExpandCell = ({
    rowData,
    dataKey,
    expandedRowKeys,
    onChange,
    ...props
  }) => (
    <Cell {...props} style={{ padding: 5 }}>
      <IconButton
        appearance="subtle"
        onClick={() => {
          onChange(rowData)
        }}
        icon={
          expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
            <CollaspedOutlineIcon />
          ) : (
            <ExpandOutlineIcon
            // onClick={(e)=>alert("Saranya")}
            />
          )
        }
      />
    </Cell>
  )
  
  export default AllTenants
  