import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const createPropertyTaxInDB = async(taxJson) => {
    try{
        const responseForCountyTax= await axios.post(`${url}/countyTax`,taxJson);
        console.log(responseForCountyTax)
        if(responseForCountyTax && responseForCountyTax.data && responseForCountyTax.data.countyTaxId){
          return responseForCountyTax.data.countyTaxId;
        }
        else{
          return "No Data Found"
        }
    }
    catch(error)
    {
        console.log(error);
    }
};
export const getTaxDetailsByPropertyIdInDB = async(propertyId) => {
    try{
        const responseForCountyTax= await axios.get(`${url}/countyTaxByPropertyId/${propertyId}`);
        console.log(responseForCountyTax)
        if(responseForCountyTax && responseForCountyTax.data && responseForCountyTax.data.length>0){
          return responseForCountyTax.data[0];
        }
        else{
          return null
        }
    }
    catch(error)
    {
        console.log(error);
    }
};
export const updatePropertyTaxInDB = async(taxJson) => {
  try{
      const responseForCountyTax= await axios.patch(`${url}/countyTax/${taxJson.id}`,taxJson);
      console.log(responseForCountyTax)
      if(responseForCountyTax && responseForCountyTax.data){
        return responseForCountyTax.data;
      }
      else{
        return "No Data Found"
      }
  }
  catch(error)
  {
      console.log(error);
  }
};
