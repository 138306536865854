import axios from "axios";
const url = process.env.REACT_APP_SERVER_URL;
export const getCustomerInfoByTenancyIdInDB = async(tenancyId) =>{
    try{
        const responseForCustomer = await axios.get(`${url}/tenants/${tenancyId}/customers`)
            if(responseForCustomer && responseForCustomer.data){
                return responseForCustomer.data;
              }
              else{
                return [];
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const getCustomerInfoByLeaseIdInDB = async(leaseId) =>{
  try{
      const responseForCustomer = await axios.get(`${url}/lease/${leaseId}/customers`)
          if(responseForCustomer && responseForCustomer.data){
              return responseForCustomer.data;
            }
            else{
              return [];
          }
  }
  catch(error)
  {
      console.log(error)
  }
}
export const updateCustomerInfoInDB = async(customerId,customerJson) =>{
    try{
        const responseForCustomer = await axios.patch(`${url}/customers/${customerId}`,customerJson)
            if(responseForCustomer && responseForCustomer.data){
                return responseForCustomer.data;
              }
              else{
                return "No Data Found";
            }
    }
    catch(error)
    {
        console.log(error)
    }
}

export const createCustomerInDB = async(leaseId, customer) =>{
    try{
       const fname=customer.fname;
       const lname=customer.lname;
       const phone=customer.phone;
       const address=customer.address;
       const email=customer.email;
       await axios.post(`${url}/customers`,{
         fname,
         lname,
         phone,
         address,
         email

       }).then(async(responseForCustomers) =>{
         console.log(responseForCustomers.data);
        const customerId=responseForCustomers.data.customerId;
        await createLeaseCustomerRelation(customerId,leaseId);
        //  await createTenancyCustomerRelation(customerId,tenancyId);
         return customerId;
       })
       return null;

    }catch(error){
         console.log(error);
     }
 };

 export const createLeaseCustomerRelation = async(customerId,leaseId) =>{
    try{
        await axios.post(`${url}/leaseCustomers`,{
            customerId,leaseId
            
           }).then((responseForRelation) =>{
             console.log(responseForRelation.data);
             
           })

    }catch(error){
         console.log(error);
     }
 }

 export const createTenancyCustomerRelation = async(customerId,tenancyId) =>{
    try{
       await axios.post(`${url}/tenancy_customer`,{
        customerId,tenancyId
        
       }).then((responseForRelation) =>{
         console.log(responseForRelation.data);
         
       })

    }catch(error){
         console.log(error);
    }
 } 
 
 export const getCustomersByPropertyIdInDB = async(propertyId) =>{
  try{
     const response  = await axios.get(`${url}/customers/getByPropertyId/${propertyId}`);
     if(response && response.data){
      return response.data;
     }
     return [];

  }catch(error){
       console.log(error);
  }
} 
