import React, { useState, useEffect , useContext } from "react";
import axios from "axios";
import { Link, redirect } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
import { NumericFormat } from 'react-number-format';
import { formatDateText, getDueDate, parseResponseJSON} from "./CommonFunctions";
import {
    Dialog, 
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Box,
    IconButton,
    
} from "@mui/material";
import {
    GridPagination,
    DataGrid,
  } from '@mui/x-data-grid'
  import CancelIcon from '@mui/icons-material/Close';
import { getPropertyInfoByTenancyIdInDB, getUnitInfoByTenancyIdInDB } from "./TenancyFunctions";
import { getPaymentScheduleByTenancyIdInDB } from "./PaymentScheduleFunctions";
import { columnGroupsStateInitializer } from "@mui/x-data-grid/internals";
import { getCustomerInfoByTenancyIdInDB } from "./CustomerFunctions";
import { getPaymentHistoryByTenancyIdInDB, getPaymentsByLeaseIdInDB } from "./PaymentFunctions";
import MakePayment from "./MakePayment";
import TakePayment from "./TakePayment";
import { format, getDay, parseISO } from "date-fns";

const PaymentHistory = ({leaseJson,unitJson,showPaymentHistoryModal,setShowPaymentHistoryModal}) => {
 
    const [customersDetails,setCustomersDetails]=useState([]);
    const [paymentHistory,setPaymentHistory] = useState([])
    const [propertyJson,setPropertyJson]=useState({})
    const [paymentSchedule,setPaymentSchedule]=useState([]);
    const [showMakePaymentModal,setShowMakePaymentModal]=useState(false);
    const [paymentId,setPaymentId] = useState(null)
    const url=process.env.REACT_APP_SERVER_URL;
    const [expectedRent,setExpectedRent] = useState(0);
    const [receivedRent,setReceivedRent] = useState(0);
    const [ showTakePaymentModal,setShowTakePaymentModal] = useState(false);
    const columns = [
        { field: 'id', headerName: 'Payment Schedule Id', width: 120, align: 'left' },
        { field: 'dueDate', headerName: 'Payment Due Date', width: 160, align: 'left',
          renderCell: (params) => {
            const cr= params.row;
            console.log(params)
            if(cr){
              const res = getDueDate(cr.rentalType,cr.dueOn, cr.week_start,
                cr.week_end, cr.month_start, cr.month_end
              );
              if(res)
                return formatDateText(res)
            }
            return null;
            
          }, },
        { field: 'rent', headerName: `Rent Due`, width: 140,
        renderCell:(params)=>{
            return <NumericFormat value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
        }
        
        },
        { field: 'deposit', headerName: `Deposit`, width: 140,
        renderCell:(params)=>{
            return <NumericFormat value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
        }
        
        },
        { field: 'paidAmount', headerName: 'Paid Amount', width: 140,
        renderCell:(params)=>{
            if(params.row.id){
            return <Link onClick={(e)=>{ setPaymentId(params.row.id);
                // setShowMakePaymentModal(true)

                setShowTakePaymentModal(true);
              }}
                >
                    <NumericFormat value={params.value} thousandSeparator prefix={'$'} displayType="text"/>
                </Link>;
            }
            else{
                return <></>;
            }
        } },
        { field: 'actualPaymentDate', headerName: 'Payment Received On', width: 160,
        valueFormatter: (params) => {
            const formattedDate = params.value ? formatDateText(params.value) :null
            return formattedDate 
          },},
        { field: 'lateFee', headerName: 'Late Fee', width: 140 },
    
        {
          field: 'balance',
          headerName: 'Balance',
          width: 140,
          renderCell:(params)=>{
            if(params.row.paymentId){
                return <NumericFormat style={{color: params.value>0 ? "red" : "green"}} 
                thousandSeparator value={-1*params.value} prefix={'$'} displayType="text"/>
            }
            else{
                return <></>
            }

        }
        }
    ]
    
      const handleClosePaymentHistoryPopup=()=>{
        setShowPaymentHistoryModal(false)
      }
     
      
      
      useEffect(() => {
        if(leaseJson && leaseJson.id){
            // getCustomerDetailsByTenancyId(leaseJson.id);
            getPaymentHistoryByLeaseId(leaseJson.id)
        }
        
        
        
      }, [unitJson,leaseJson,showMakePaymentModal]);
      const getCustomerDetailsByTenancyId= async(tenancyId)=>{
        try{
            const response= await getCustomerInfoByTenancyIdInDB(tenancyId);
            if(response){
                setCustomersDetails(response)
            }

        }catch(error){
            console.log(error);
        }

      }
      const getPaymentHistoryByLeaseId= async(leaseId)=>{
        try{
            const response=await getPaymentsByLeaseIdInDB(leaseId);
            if(response){
                console.log(response)
                setPaymentHistory(runningBalance(response))

            }

        }catch(error){
            console.log(error)
        }
      }
      const runningBalance = (paymentHistory)=>{
        let balance=0;
        let expectedRent=0,receivedRent=0;
        if(paymentHistory){
       for(var index=0;index<paymentHistory.length;index++){
        var row=paymentHistory[index];
        
        receivedRent +=parseInt(row.paidAmount)
        if(index==0){
          const exRent=parseInt(row.rent)+parseInt(row.deposit || 0) + parseInt(row.tax|| 0);
            expectedRent +=exRent;
            if(row.paidAmount==null)
            row["balance"]=null;
            else{
                balance=exRent - row.paidAmount;
                row["balance"]=balance;
            }
            
        }
        else{
            if(paymentHistory[index].id==paymentHistory[index-1].id){
                
                balance=balance-row.paidAmount;
                row["balance"]=balance;

            }
            else{
              const exRent=parseInt(row.rent)+parseInt(row.deposit || 0) + parseInt(row.tax|| 0);
                expectedRent +=exRent;
                if(row.paidAmount==null){
                    row["balance"]=null;
                    balance=0;
                }
                else{
                    balance=exRent - row.paidAmount;
                    row["balance"]=balance;
                }

            }
        }
       }
        }
        console.log(paymentHistory)
        setExpectedRent(expectedRent);
        setReceivedRent(receivedRent);
       return paymentHistory;
      }
      function CustomPagination(props) {
        return <><b>Expected Rent:
            <NumericFormat value={expectedRent} thousandSeparator prefix={'$'} displayType="text"/></b>
            <b>Received Amount:
            <NumericFormat value={receivedRent} thousandSeparator prefix={'$'} displayType="text"/></b>
            <GridPagination  {...props} /> </>;
      }
    
    
    
    return (
        <>
      <Dialog
        open={showPaymentHistoryModal}
        onClose={handleClosePaymentHistoryPopup}
        maxWidth="lg"
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>Payment History  <IconButton aria-label="close" onClick={handleClosePaymentHistoryPopup}>
            <CancelIcon />
        </IconButton></DialogTitle>
        <DialogContent>
          <DialogContentText>{unitJson.name} , 
          </DialogContentText>
          <Box
        sx={{
          height: 500,
          width: '100%',
          '& .actions': {
            color: 'text.secondary',
          },
          '& .textPrimary': {
            color: 'text.primary',
          },
        }}
      >
        <DataGrid rows={paymentHistory} columns={columns} 
        getRowClassName={(params) =>{
            if(params.row.id%2==0)
            return 'even-row';
            else
            return 'odd-row'
          }}
          getRowId={(row) => row.id}
          slots={{
            pagination: CustomPagination,
          }}
          
         
        />
      </Box>
        </DialogContent>
      </Dialog>
      {showMakePaymentModal && <MakePayment showMakePaymentModal={showMakePaymentModal}
      setShowMakePaymentModal={setShowMakePaymentModal} paymentId={paymentId}
       />}   

       {/* {showTakePaymentModal && <TakePayment leaseJson={{id: leaseId}}  
           showTakePaymentModal={showTakePaymentModal}
           setShowTakePaymentModal={setShowTakePaymentModal}/>} */}
            
        </>
    );

}
export default PaymentHistory;